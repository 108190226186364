import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React, { useState, useEffect } from "react";
import "../styles/Courses.css";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function SortableCourse(props) {
    const [isDragging, setIsDragging] = useState(false);

    const course_id = useParams().courseId;
    const navigate = useNavigate();

    useEffect(() => {
        setIsDragging(props.isDragging);
    }, [props.isDragging]);

    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({ id: props.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const colors = [
        "#000000",
        "#FFDB7E",
        "#EA95FF",
        "#96BAF0",
        "#84CE83",
        "#FF8383",
        "#FF9C54",
        "#B5B5B5",
        "#555555",
        "#A700D1",
    ];

    return (
        <div
            className="course"
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
        >
            {isDragging ? (
                <button id={props.id}>
                    <div
                        className="course-priority"
                        style={{ backgroundColor: colors[props.priority] }}
                    >
                        {props.priority}
                    </div>
                    <div className="course-name">
                        {props.course_info_full.dept}
                        {props.course_info_full.code}
                    </div>
                </button>
            ) : (
                <button
                    className="course-button"
                    id={props.id}
                    style={{
                        backgroundColor:
                            parseInt(course_id) === props.id
                                ? colors[props.priority]
                                : "white",
                    }}
                    onClick={() => navigate("/course/" + props.id)}
                >
                    <div
                        className="course-priority"
                        style={{ backgroundColor: colors[props.priority] }}
                    >
                        {props.priority}
                    </div>
                    <div className="course-name">
                        {props.course_info_full.dept}
                        {props.course_info_full.code}
                    </div>
                </button>
            )}
        </div>
    );
}
