import React from "react";
import CreateCourse from "../../pages/createCourse";
import PropTypes from "prop-types";
import { Modal, Button } from "@mantine/core";

export default function CreateCourseButton({
    opened,
    toggleModal,
    closeModal,
    syllabusFile,
}) {
    return (
        <>
            <Button onClick={toggleModal} variant="default">
                Create A New Course (manually)
            </Button>
            <Modal
                size="xl"
                opened={opened}
                onClose={closeModal}
                centered
                radius={15}
                title="Create A New Course!"
                overlayProps={{
                    backgroundOpacity: 0.55,
                    blur: 3,
                }}
            >
                <h3>Create A New Course!</h3>
                <CreateCourse syllabusFile={syllabusFile} />
            </Modal>
        </>
    );
}

CreateCourseButton.propTypes = {
    opened: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    syllabusFile: PropTypes.instanceOf(File),
};
