import React, { useEffect, useState } from "react";
import CustomTableRow from "./customTableRow";
import "../../styles/ToDo.css";
import { API_URL } from "../../constants";
import { useOutletContext } from "react-router-dom";
import courseService from "../../api/courseService";
import { Modal, Button } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import CreateTaskForm from "../general/createTaskForm";
import addNewTask from "../../images/addNewTask.png";

const TaskList = () => {
    const [todo] = useOutletContext()[0];
    const [heap, setHeap] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [opened, { open, close }] = useDisclosure(false);

    useEffect(() => {
        setIsLoaded(false);
    }, []);

    useEffect(() => {
        const getCourseInfo = async (courseId) => {
            setIsLoaded(false);

            return await courseService.getUserCourse(courseId);
        };

        const updateHeap = async () => {
            const updatedHeap = [];
            for (const todoItem of todo) {
                const courseInfo = await getCourseInfo(todoItem.course);
                if (courseInfo) {
                    updatedHeap.push({
                        ...todoItem,
                        course_info: courseInfo,
                    });
                }
            }
            updatedHeap.sort((a, b) => (a.rank < b.rank ? 1 : -1));

            // change positions of heap elements
            for (let i = 0; i < updatedHeap.length; i++) {
                updatedHeap[i].last_position = updatedHeap[i].curr_position;
                updatedHeap[i].curr_position = i;

                const authTokens = JSON.parse(
                    localStorage.getItem("authTokens"),
                );
                // update the database
                const response = await fetch(
                    API_URL + "api/task/" + updatedHeap[i].id + "/update",
                    {
                        method: "PATCH",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + authTokens.access,
                        },
                        // remove course_info and task_info from the object
                        body: JSON.stringify({
                            curr_position: updatedHeap[i].curr_position,
                            last_position: updatedHeap[i].last_position,
                            rank: updatedHeap[i].rank,
                            status: updatedHeap[i].status,
                        }),
                    },
                );
                await response.json();
            }
            // sort the todo items by rank and remove all the items with rank less than 0
            setHeap(updatedHeap);
            setIsLoaded(true);
        };

        updateHeap();
    }, [todo]);

    return (
        <div className="list-box">
            {!isLoaded || !heap ? (
                <div className="no-tasks">Loading...</div>
            ) : heap.length == 0 ? (
                <div className="no-tasks">No tasks today &#128516;</div>
            ) : (
                <>
                    <table
                        title="Syllabyte"
                        className="todo-table"
                        id="col-syllabyte-list-desktop"
                    >
                        <colgroup>
                            <col style={{ width: "10%" }} />
                            <col style={{ width: "15%" }} />
                            <col style={{ width: "30%" }} />
                            <col style={{ width: "25%" }} />
                            <col style={{ width: "10%" }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th colSpan={5}>Today&apos;s Tasks</th>
                            </tr>
                        </thead>
                        <tbody>
                            {heap.map((todo) => (
                                <CustomTableRow key={todo.id} todo={todo} />
                            ))}
                        </tbody>
                    </table>
                    <table
                        title="Syllabyte"
                        className="todo-table"
                        id="col-syllabyte-list-phone"
                    >
                        <colgroup>
                            <col style={{ width: "15%" }} />
                            <col style={{ width: "25%" }} />
                            <col style={{ width: "40%" }} />
                            <col style={{ width: "20%" }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th colSpan={4}>Today&apos;s Tasks</th>
                            </tr>
                        </thead>
                        <tbody>
                            {heap.map((todo) => (
                                <CustomTableRow key={todo.id} todo={todo} />
                            ))}
                        </tbody>
                    </table>
                </>
            )}
            <div className="task-list-footer">
                <div className="add-new-task-wrapper">
                    <Button onClick={open} className="add-new-task-button">
                        <img src={addNewTask} alt="Add New Task" />
                    </Button>
                    <Modal
                        opened={opened}
                        onClose={close}
                        variant="default"
                        size="xs"
                        centered
                        radius={10}
                        withCloseButton={false}
                        styles={{
                            body: {
                                border: "2px dashed #989898",
                            },
                        }}
                    >
                        <CreateTaskForm onClose={close} />
                    </Modal>
                </div>
            </div>
        </div>
    );
};

export default TaskList;
