import React from "react";
import "../../styles/general/PrefCheck.css";
const PrefCheck = (props) => {
    return (
        <div className="pref-check">
            <div className="pref-check-checkbox">
                <input
                    type="checkbox"
                    name={props.name}
                    checked={props.pref}
                    onChange={props.onChange}
                />
            </div>
            <div className="pref-check-description">{props.description}</div>
        </div>
    );
};

export default PrefCheck;
