import axiosInstance from "./axiosConfig";
import { showCustomNotification } from "../utils/notification";

const courseService = {
    getAllCoursesForUser: async () => {
        try {
            return await axiosInstance.get("api/courses");
        } catch (error) {
            throw error;
        }
    },

    createCourse: async (courseData) => {
        try {
            const response = await axiosInstance.post(
                "api/courseDB/create-and-enrol",
                courseData,
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    parseSyllabus: async (id) => {
        try {
            const response = await axiosInstance.post(
                "api/course/parse-syllabus",
                id,
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    getUserCourse: async (id) => {
        try {
            const response = await axiosInstance.get("api/course/" + id);
            return await response.data;
        } catch (error) {
            throw error;
        }
    },

    getCourseDB: async (id) => {
        try {
            const response = await axiosInstance.get("api/courseDB/" + id);
            return await response.data;
        } catch (error) {
            throw error;
        }
    },

    getCourseByShareId: async (share_id) => {
        try {
            const response = await axiosInstance.get(
                "api/courseDB/share-id/" + share_id,
            );

            if (response.status === 404) {
                throw new Error("Course not found");
            }

            return await response.data;
        } catch (error) {
            throw error;
        }
    },

    // Update a user's personal course item (Course Model)
    updatePersonalCourse: async (courseData) => {
        try {
            const response = await axiosInstance.patch(
                `api/course/${courseData.id}/update`,
                courseData,
            );
            return response;
        } catch (error) {
            showCustomNotification({
                title: "Update Course Failed",
                message: "try refreshing the page",
                color: "red",
            });
            throw error;
        }
    },

    // Update a owner's public course item (CourseDB Model)
    updateCourseDB: async (courseData) => {
        try {
            const response = await axiosInstance.patch(
                `api/courseDB/${courseData.id}/update`,
                courseData,
            );

            return response;
        } catch (error) {
            showCustomNotification({
                title: "Update Course Failed",
                message: "try refreshing the page",
                color: "red",
            });
            throw error;
        }
    },
};

export default courseService;
