import React from "react";
import { useState, useEffect } from "react";
import "../../styles/landingPage/Hero.css";

const CourseHero = ({ speed, images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        setCurrentIndex(Math.floor(Math.random() * images.length));

        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 5000);

        return () => clearInterval(interval);
    }, [images.length]);

    useEffect(() => {
        const imageContainer = document.querySelector(
            `.image-container#s${speed}`,
        );
        // set speed of transition
        if (imageContainer) {
            imageContainer.style.animation = `scrollAnimation ${speed}ms linear infinite`;
        }
    }, []);

    return (
        <div className="hero-section">
            <div className="image-container" id={`s${speed}`}>
                <img
                    src={images[currentIndex]}
                    alt={`Image ${currentIndex + 1}`}
                />
                <img
                    src={images[(currentIndex + 1) % images.length]}
                    alt={`Image ${currentIndex + 2}`}
                />
            </div>
        </div>
    );
};

export default CourseHero;
