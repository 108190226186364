// components/SyllabusUploader.js
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Group, FileInput, Button } from "@mantine/core";
import { IconFileTypePdf } from "@tabler/icons-react";

export default function SyllabusUploader({ onUpload, initialFile }) {
    const [syllabusFile, setSyllabusFile] = useState(initialFile || null);

    return (
        <Group gap={5}>
            <FileInput
                clearable
                placeholder="Upload Syllabus"
                leftSection={<IconFileTypePdf size={20} />}
                accept="application/pdf"
                value={syllabusFile}
                onChange={setSyllabusFile}
            />

            <Button
                size="s"
                color="green.5"
                onClick={() => onUpload(syllabusFile)}
            >
                Go!
            </Button>
        </Group>
    );
}

SyllabusUploader.propTypes = {
    onUpload: PropTypes.func.isRequired,
    initialFile: PropTypes.instanceOf(File),
};
