import React, { useState, useEffect } from "react";
import { useFeatureFlags } from "../../contexts/featureFlagContext";
import TaskTable from "../addCoursePage/taskTable";
import "../../styles/CoursePreviewModal.css";
import taskService from "../../api/taskService";
import { Button, Group, Text, Title, Paper } from "@mantine/core";
import "../../styles/Settings.css";
import { showCustomNotification } from "../../utils/notification";

const GradeDisplay = ({ label, value }) => (
    <Text>
        <Text component="span" fw={700} c="black">
            {label}:
        </Text>{" "}
        <Text component="span" fw={700} c="black">
            {value.toFixed(2)}%
        </Text>
    </Text>
);

const GradeCalculator = ({ tasks: initialTasks, isPublic = false }) => {
    const [tasks, setTasks] = useState(initialTasks);
    const [grades, setGrades] = useState({
        current: 0,
        max: 0,
        total: 0,
    });
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const featureFlags = useFeatureFlags();

    const parsePercentage = (value) => {
        const percentage = parseFloat(value);
        return !isNaN(percentage) && percentage >= 0 && percentage <= 100
            ? percentage / 100
            : null;
    };

    const parseFraction = (value) => {
        const [numerator, denominator] = value.split("/").map(Number);
        return !isNaN(numerator) &&
            !isNaN(denominator) &&
            denominator !== 0 &&
            numerator >= 0 &&
            numerator <= denominator
            ? numerator / denominator
            : null;
    };

    const parseDecimal = (value) => {
        const number = parseFloat(value);
        return !isNaN(number) && number >= 0 && number <= 1 ? number : null;
    };

    const parseAndValidateGrade = (value) => {
        if (value === "" || value === null || value === undefined) {
            return { isValid: true, parsedValue: null };
        }
        const trimmedValue = value.toString().trim();

        let parsedValue = null;
        if (trimmedValue.endsWith("%")) {
            parsedValue = parsePercentage(trimmedValue);
        } else if (trimmedValue.includes("/")) {
            parsedValue = parseFraction(trimmedValue);
        } else {
            parsedValue = parseDecimal(trimmedValue);
        }

        return {
            isValid: parsedValue !== null || trimmedValue === "",
            parsedValue,
        };
    };

    const handleGradeChange = (taskId, value) => {
        const updatedTasks = tasks.map((task) =>
            task.id === taskId ? { ...task, inputGrade: value } : task,
        );
        setTasks(updatedTasks);
        setUnsavedChanges(true);
    };

    const calculateGrades = async () => {
        const updatedTasks = tasks.map((task) => {
            const { isValid, parsedValue } = parseAndValidateGrade(
                task.inputGrade,
            );
            if (!isValid) {
                return task; // Return the original task if invalid
            }
            return {
                ...task,
                grade: parsedValue,
            };
        });

        // Check if any task was invalid
        const invalidTask = updatedTasks.find(
            (task) => !parseAndValidateGrade(task.inputGrade).isValid,
        );
        if (invalidTask) {
            showCustomNotification({
                title: "Invalid Grade",
                message: `Invalid grade "${invalidTask.inputGrade}" for task "${invalidTask.title}". 
                Please enter a valid grade (e.g., 75%, 3/4, or 0.75)`,
                color: "red",
            });
            return; // Exit the function if any grade was invalid
        }

        setTasks(updatedTasks);
        updateGrades(updatedTasks);
        setUnsavedChanges(false);

        if (!isPublic) {
            try {
                await Promise.all(
                    updatedTasks.map((task) =>
                        taskService.updateTaskGrade(task.id, task.grade),
                    ),
                );
            } catch (error) {
                console.error("Failed to update grades in backend:", error);
                showCustomNotification({
                    title: "Error",
                    message: "Failed to save grades",
                    color: "red",
                });
            }
        }

        showCustomNotification({
            title: "Grades updated",
            message: "Grades updated successfully",
            color: "green",
        });
    };

    useEffect(() => {
        // Initialize inputGrade and grade based on isPublic
        const updatedTasks = initialTasks.map((task) => ({
            ...task,
            inputGrade: isPublic ? null : task.grade,
            grade: isPublic ? null : task.grade,
        }));
        setTasks(updatedTasks);
        updateGrades(updatedTasks);
    }, [initialTasks, isPublic]);

    const updateGrades = (updatedTasks) => {
        const totalWorth = updatedTasks.reduce(
            (sum, task) => sum + task.worth,
            0,
        );
        const currentGrade = updatedTasks.reduce(
            (sum, task) => sum + (task.grade ? task.grade * task.worth : 0),
            0,
        );
        const maxGrade = updatedTasks.reduce(
            (sum, task) => sum + (task.grade ? task.grade : 1) * task.worth,
            0,
        );

        if (totalWorth === 0) {
            setGrades({ current: 0, max: 0, total: 0 });
        } else {
            setGrades({
                current: currentGrade,
                max: maxGrade,
                total: totalWorth,
            });
        }
    };

    if (!featureFlags.gradeCalculator) {
        return <div>Coming soon!</div>;
    }

    return (
        <>
            <br />
            <Paper p="md" shadow="xs" withBorder bg="yellow.3">
                <Title order={4} mb="sm" c="black">
                    How to use the Grade Calculator
                </Title>
                <Text c="black">
                    Enter grades as fractions (e.g., 3/4), percentages (e.g.,
                    75%), or decimals (e.g., 0.75). Click &quot;Calculate
                    Grades&quot; to update your grades.
                </Text>
            </Paper>
            <TaskTable
                tasks={tasks}
                tableType="assignment"
                showGrades={true}
                onGradeChange={handleGradeChange}
            />
            <TaskTable
                tasks={tasks}
                tableType="exam"
                showGrades={true}
                onGradeChange={handleGradeChange}
            />
            {/* TODO: should update to goofy button component later */}
            <Button
                onClick={calculateGrades}
                disabled={!unsavedChanges}
                style={{ marginTop: "10px", marginBottom: "10px" }}
            >
                Calculate Grades
            </Button>
            <Paper p="md" shadow="xs" withBorder bg="gray.1">
                <Title order={3} mb="sm" c="black">
                    Grade Summary
                </Title>
                <Group position="apart" grow align="top">
                    <GradeDisplay
                        label="Current Grade"
                        value={grades.current}
                    />
                    <GradeDisplay
                        label="Max Predicted Grade"
                        value={grades.max}
                    />
                    <GradeDisplay
                        label="Total Course Worth"
                        value={grades.total}
                    />
                </Group>
            </Paper>
            <br />
        </>
    );
};

export default GradeCalculator;
