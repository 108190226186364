import React from "react";
import { useSelectedTask } from "../../contexts/selectedTaskContext";
import Link from "../../images/Link.png";
import Edit from "../../images/Edit.png";
import { getTodaysDate } from "../../utils/generalUtils";
import "../../styles/AboutTask.css";

const AboutTask = () => {
    const { selectedTask, setSelectedTask } = useSelectedTask();

    return selectedTask ? (
        <div className="about-task">
            <div className="about-task-title">
                <p>{selectedTask.title}</p>
                <div className="close-task">
                    <button
                        onClick={() => {
                            setSelectedTask(null);
                        }}
                    >
                        x
                    </button>
                </div>
            </div>
            <div className="about-task-detail">
                <p>Worth:</p> <p>{selectedTask.worth}%</p>
            </div>
            <div className="about-task-detail">
                <p>Due on:</p>
                {selectedTask.due_date === getTodaysDate() ? (
                    <p>Today</p>
                ) : (
                    <p>{selectedTask.due_date}</p>
                )}
            </div>

            <div className="about-task-buttons">
                <button
                    onClick={() => {
                        window.open(selectedTask.external_link, "_blank");
                    }}
                    disabled={selectedTask.external_link == ""}
                >
                    <p>{selectedTask.external_link}</p>
                    <img src={Link} alt="link" />
                </button>

                {/* TODO, ADD A EDIT ICON THAT OPENS A NOTES EDITOR */}
                {/* <div className="about-task-notes">
                    <img src={Edit} alt="edit" />
                </div> */}
            </div>
        </div>
    ) : (
        <div className="about-task">
            <div className="no-task-selected">
                Click on a task to see more information
            </div>
        </div>
    );
};

export default AboutTask;
