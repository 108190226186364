import React from "react";
import AddNewCourse from "./addNewCourseButton";
import Courses from "./courses";
import Profile from "./profile";
import "../styles/Sidebar.css";
import FeedbackLink from "./feedbackLink";

const Sidebar = ({ courses, user, coursesLoaded }) => {
    return (
        <div>
            <Profile user={user} />
            <AddNewCourse />
            <Courses courseInfo={courses} coursesLoaded={coursesLoaded} />
            {courses.length > 0 ? (
                courses[0].course_info_full.is_demo == true ? (
                    <div className="sidebar-waitlist">
                        Like what you see?
                        <button
                            className="sidebar-button-general"
                            type="button"
                            onClick={() => {
                                window.open(
                                    "https://forms.fillout.com/t/sxEYar2xp5us",
                                    "_blank",
                                );
                            }}
                        >
                            Waitlist
                        </button>
                    </div>
                ) : null
            ) : null}
            <FeedbackLink />
        </div>
    );
};

export default Sidebar;
