import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Root from "./pages/root";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./pages/errorPage.js";
import SyllabyteHomePage from "./pages/syllabyteHomePage.js";
import CourseSelection from "./pages/courseSelection.js";
import Settings from "./pages/settings.js";
import CoursePage from "./pages/coursePage.js";
import LoginPage from "./pages/loginPage.js";
import RegisterPage from "./pages/registerPage.js";
import StatHaven from "./pages/statHaven.js";
import CalendarView from "./components/calendarView.js";
import Verify from "./pages/verify.js";
import Demo from "./pages/demo.js";
import PublicCoursePage from "./pages/publicCoursePage.js";
import courseService from "./api/courseService.js";
import taskService from "./api/taskService.js";
import "./fonts/static/Outfit-Light.ttf";
import "./styles/fonts.css";
import { createTheme, MantineProvider } from "@mantine/core";
import { FeatureFlagProvider } from "./contexts/featureFlagContext.js";
import { Notifications } from "@mantine/notifications";
import { ModalsProvider } from "@mantine/modals";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "home",
                element: <SyllabyteHomePage />,
            },
            {
                path: "addcourse",
                element: <CourseSelection />,
            },
            {
                path: "settings",
                element: <Settings />,
            },
            {
                path: "course/:courseId",
                element: <CoursePage />,
            },
            {
                path: "stathaven",
                element: <StatHaven />,
            },
            {
                path: "syllabyte-calendar",
                element: <CalendarView />,
                loader: async () => {
                    const tasks = await taskService.getAllUserTasks();
                    return { tasks: tasks.data };
                },
            },
        ],
    },
    {
        path: "/login",
        element: <LoginPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/signup",
        element: <RegisterPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/verify-email/:uidb64/:token",
        element: <Verify />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/irl",
        element: <Demo />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/courses/public/:share_id",
        element: <PublicCoursePage />,
        errorElement: <ErrorPage />,
        loader: async ({ params }) => {
            const course = await courseService.getCourseByShareId(
                params.share_id,
            );
            return course;
        },
    },
]);

const theme = createTheme({
    fontFamily: "MyOutfit-L, sans-serif",
    /** Put your mantine theme override here */
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <MantineProvider theme={theme}>
            <Notifications />
            <ModalsProvider>
                <FeatureFlagProvider>
                    <RouterProvider router={router} />
                </FeatureFlagProvider>
            </ModalsProvider>
        </MantineProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
