import React from "react";
import PropTypes from "prop-types";
import {
    TextInput,
    NumberInput,
    Group,
    ActionIcon,
    Text,
    Button,
    Box,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { randomId } from "@mantine/hooks";
import { IconTrash } from "@tabler/icons-react";
import { EXAM, TaskTypes } from "../../constants/courseConstants";

const ColumnHeader = ({ children, ...props }) => (
    <Text
        fw={500}
        size="sm"
        style={{ flex: 1, ...props.style }}
        miw={80}
        c="black"
        {...props}
    >
        {children}
        {props.required && <span style={{ color: "red" }}>*</span>}
    </Text>
);

const TaskSection = ({ form, taskType, description }) => {
    const taskListName = taskType === EXAM ? "examTasks" : "assignmentTasks";

    const taskFields = form.getValues()[taskListName].map((task, index) => (
        <Group key={task.key} mt="xs" align="flex-start">
            <TextInput
                required
                miw={200}
                width="100%"
                placeholder={`${taskType} ${index + 1}`}
                style={{ flex: 1 }}
                key={form.key(`${taskListName}.${index}.title`)}
                {...form.getInputProps(`${taskListName}.${index}.title`)}
            />
            <DateInput
                clearable
                miw={80}
                placeholder="YYYY-MM-DD"
                style={{ flex: 1 }}
                key={form.key(`${taskListName}.${index}.dueDate`)}
                {...form.getInputProps(`${taskListName}.${index}.dueDate`)}
            />
            <NumberInput
                required
                placeholder="0"
                min={0}
                max={100}
                style={{ flex: 1 }}
                key={form.key(`${taskListName}.${index}.worth`)}
                {...form.getInputProps(`${taskListName}.${index}.worth`)}
            />
            <ActionIcon
                color="red"
                onClick={() => form.removeListItem(taskListName, index)}
            >
                <IconTrash size="1rem" />
            </ActionIcon>
        </Group>
    ));

    return (
        <>
            <Text mt="lg" size="lg" fw={700} mb={0} c="black">
                {taskType == EXAM ? "Exams" : "Assignments"}
            </Text>
            <Text c="dimmed" size="sm" mt="0" mb="sm">
                {description}
            </Text>
            <Box mx="auto">
                {taskFields.length > 0 ? (
                    <Group mb="xs" align="flex-start">
                        <ColumnHeader required>Name</ColumnHeader>
                        <ColumnHeader>Due Date</ColumnHeader>
                        <ColumnHeader required pr={40}>
                            Worth (%)
                        </ColumnHeader>
                    </Group>
                ) : (
                    <Text c="dimmed" ta="center">
                        No tasks added...
                    </Text>
                )}
                {taskFields}
                <Group justify="left" mt="md">
                    <Button
                        onClick={() =>
                            form.insertListItem(taskListName, {
                                title: "",
                                dueDate: null,
                                worth: null,
                                key: randomId(),
                                categories: [taskType.toLowerCase()],
                            })
                        }
                    >
                        Add {taskType}
                    </Button>
                </Group>
            </Box>
        </>
    );
};

TaskSection.propTypes = {
    form: PropTypes.object.isRequired,
    taskType: PropTypes.oneOf(TaskTypes).isRequired,
};

export default TaskSection;
