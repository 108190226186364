import React from "react";
import { useEffect, useState, useContext } from "react";
import Up from "../../images/Up.png";
import Down from "../../images/Down.png";
import Subtract from "../../images/Subtract.png";
import { useSelectedTask } from "../../contexts/selectedTaskContext";
import { ChangeContext } from "../../contexts/changeContext";
import "../../styles/ToDo.css";
import { API_URL } from "../../constants";
import { useFeatureFlags } from "../../contexts/featureFlagContext";

const CustomTableRow = ({ todo }) => {
    const [data, setData] = useState([]);
    const changeContext = useContext(ChangeContext);
    const featureFlags = useFeatureFlags();
    const { setSelectedTask } = useSelectedTask();

    useEffect(() => {
        setData(todo);
    }, []);

    const updateStatus = async (status) => {
        const response = await fetch(
            API_URL + "api/task/" + todo.id + "/done",
            {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    Authorization:
                        "Bearer " +
                        JSON.parse(localStorage.getItem("authTokens")).access,
                },
                body: JSON.stringify(status),
            },
        );
        const data = await response;
        if (response.status === 401) {
            localStorage.removeItem("authTokens");
            window.location.reload(false);
        } else if (response.status === 200) {
            return data;
        }
    };

    const changeState = () => {
        const new_data = { ...data };
        if (data.status === 2) {
            updateStatus(0).then(() => {
                new_data.status = 0;
                setData(new_data);
                changeContext();
            });
        } else {
            updateStatus(2).then(() => {
                new_data.status = 2;
                setData(new_data);
                changeContext();
            });
        }
    };

    return (
        <tr
            style={{
                backgroundColor:
                    data.status === 2
                        ? "white"
                        : data.rel_importance == 3
                          ? "#FF8383"
                          : data.rel_importance == 2
                            ? "#FFBD59"
                            : "#58D7FF",
                cursor: "pointer",
            }}
            onClick={() => {
                setSelectedTask(data);
            }}
        >
            <td id="col-arrow">
                <img
                    src={
                        todo.curr_position < todo.last_position
                            ? Up
                            : todo.curr_position > todo.last_position
                              ? Down
                              : Subtract
                    }
                    alt="arrow"
                />
            </td>
            <td style={{ textAlign: "center" }} className="col-course">
                {todo.course_info.dept}
                {todo.course_info.code}
            </td>
            <td
                style={{
                    textAlign: "left",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "200px",
                }}
                className="col-name"
            >
                {featureFlags.showTodoWorthInMainList ? (
                    <>
                        {todo.title} ({todo.worth}%)
                    </>
                ) : (
                    todo.title
                )}
            </td>
            <td id="col-date">{todo.due_date}</td>
            <td>
                <label className="container" id="col-tick">
                    <input
                        type="checkbox"
                        checked={data.status === 2}
                        onChange={changeState}
                    />
                    <span className="checkmark"></span>
                </label>
            </td>
        </tr>
    );
};

export default CustomTableRow;
