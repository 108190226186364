import { notifications } from "@mantine/notifications";

const notificationStyles = {
    root: {
        border: "2px dashed",
        borderRadius: "6px",
    },
};

export const showCustomNotification = ({ title, message, color }) => {
    notifications.show({
        title,
        message,
        color,
        styles: {
            root: {
                ...notificationStyles.root,
                borderColor: color,
            },
        },
    });
};
