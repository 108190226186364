import React from "react";
import "../styles/Settings.css";
import { useNavigate } from "react-router-dom";
import Settings from "../images/Settings.png";

const SettingsButton = () => {
    const navigate = useNavigate();
    return (
        <div
            className="settings-button"
            onClick={() => {
                navigate("/settings");
            }}
        >
            <div className="button-left">Settings</div>
            <img
                src={Settings}
                alt="Settings Button"
                className="button-right"
            />
        </div>
    );
};

export default SettingsButton;
