import React from "react";
import "../../styles/Settings.css";
import ConfidenceSlider from "../confidenceSlider.js";
import { useOutletContext } from "react-router-dom";

const Preferences = (props) => {
    const [userInfo] = useOutletContext()[1];

    return (
        <div className="preferences">
            <div
                className="prefs"
                style={{ width: props.show_explanation ? "40%" : "100%" }}
            >
                <div className="prefs-title">
                    Confidence
                    {props.type === "course"
                        ? props.course_name
                            ? " for " + props.course_name
                            : "Loading..."
                        : ""}
                </div>
                <div className="exam-conf">
                    <ConfidenceSlider
                        name="Exam Confidence"
                        type={props.type}
                        course_id={
                            props.type == "course" ? props.course_id : null
                        }
                        confidence={
                            props.type == "course"
                                ? props.exam_confidence
                                : props.type == "user"
                                  ? userInfo.exam_confidence
                                  : null
                        }
                    />
                </div>
                <div className="assignment-conf">
                    <ConfidenceSlider
                        name="Assignment Confidence"
                        type={props.type}
                        course_id={
                            props.type == "course" ? props.course_id : null
                        }
                        confidence={
                            props.type == "course"
                                ? props.assignment_confidence
                                : props.type == "user"
                                  ? userInfo.assignment_confidence
                                  : null
                        }
                    />
                </div>
            </div>
            <div
                className="prefs-explanation"
                style={{
                    display: props.show_explanation === true ? "flex" : "none",
                }}
            >
                <div className="prefs-explanation-title">
                    What is Confidence?
                </div>
                <div className="prefs-explanation-assignment">
                    This decides how much time you think you need to study. The
                    more confident you are, the less time you need. This weighs
                    into your Syllabyte list!
                </div>
            </div>
        </div>
    );
};

export default Preferences;
