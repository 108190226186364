export const ASSIGNMENT = "assignment";
export const EXAM = "exam";
export const TaskTypes = [EXAM, ASSIGNMENT];

const ECE345CourseInfo = {
    title: "Data Structures And Algorithms",
    department: "ECE",
    code: 345,
    year: 2022,
    semester: "Fall",
    examTasks: [
        {
            title: "Midterm",
            dueDate: "2022-10-30",
            worth: 25,
            categories: ["exam"],
        },
        {
            title: "Final",
            dueDate: "2022-12-12",
            worth: 45,
            categories: ["exam"],
        },
    ],
    assignmentTasks: [
        {
            title: "Lab 1",
            dueDate: "2022-09-30",
            worth: 15,
            categories: ["assignment"],
        },
        {
            title: "Assignment 2",
            dueDate: "2022-11-25",
            worth: 15,
            categories: ["assignment"],
        },
    ],
};
export const CreateCoursePlaceholderInfo = ECE345CourseInfo;

export const FALL = "Fall";
export const WINTER = "Winter";
export const SUMMER = "Summer";
export const SEMESTERS = new Map([
    [
        1,
        {
            name: FALL,
            color: "orange",
            monthRange: [8, 9, 10, 11],
        },
    ],
    [
        2,
        {
            name: WINTER,
            color: "blue",
            monthRange: [0, 1, 2, 3],
        },
    ],
    [
        3,
        {
            name: SUMMER,
            color: "green",
            monthRange: [4, 5, 6, 7],
        },
    ],
]);

// SemesterNameOptions is a map of semester names to their corresponding numbers
export const SemesterNameOptions = new Map(
    Array.from(SEMESTERS.entries()).map(([number, { name }]) => [name, number]),
);

// Test data for parsing syllabus (so we don't waste tokens on making an actual
// request everytime)
export const testSyllabusData = {
    message: "Course parsed",
    result: {
        course: {
            university: "University of Toronto",
            title: "Electrical Fundamentals",
            courseCode: "ECE 110S",
            department: "ECE",
            code: 110,
            year: 2021,
            semester: "Winter",
            tasks: [
                {
                    title: "Assignment 1",
                    dueDate: "2021-01-30",
                    worth: 15,
                    type: "Assignment",
                },
                {
                    title: "Assignment 2",
                    dueDate: "2021-02-13",
                    worth: 15,
                    type: "Assignment",
                },
                {
                    title: "Assignment 3",
                    dueDate: "2021-03-06",
                    worth: 15,
                    type: "Assignment",
                },
                {
                    title: "Assignment 4",
                    dueDate: "2021-03-20",
                    worth: 15,
                    type: "Assignment",
                },
                {
                    title: "Assignment 5",
                    dueDate: "2021-04-03",
                    worth: 15,
                    type: "Assignment",
                },
                {
                    title: "Assignment 6",
                    dueDate: "2021-04-10",
                    worth: 15,
                    type: "Assignment",
                },
                {
                    title: "Assignment 7",
                    dueDate: "2021-04-17",
                    worth: 15,
                    type: "Assignment",
                },
                {
                    title: "Term Test 1",
                    dueDate: null,
                    worth: 25,
                    type: "Exam",
                },
                {
                    title: "Term Test 2",
                    dueDate: null,
                    worth: 25,
                    type: "Exam",
                },
                {
                    title: "Final Assessment",
                    dueDate: null,
                    worth: 35,
                    type: "Exam",
                },
                {
                    title: "Discussion Board",
                    dueDate: "null",
                    worth: 0,
                    type: "Assignment",
                },
            ],
        },
    },
};

export const anotherTestSyllabusData = {
    message: "Course parsed",
    result: {
        course: {
            university: "University of Toronto",
            title: "Electrical Fundamentals",
            courseCode: "ECE 110S",
            department: "ECE",
            code: 110,
            year: 2021,
            semester: "Winter",
            tasks: [
                {
                    title: "Discussion Board",
                    dueDate: "null",
                    worth: 0,
                    type: "Assignment",
                },
                {
                    title: "Online Assignment 0 (Practice)",
                    dueDate: "null",
                    worth: 0,
                    type: "Assignment",
                },
                {
                    title: "Assignment 1",
                    dueDate: "2021-01-30",
                    worth: 15,
                    type: "Assignment",
                },
                {
                    title: "Assignment 2",
                    dueDate: "2021-02-13",
                    worth: 15,
                    type: "Assignment",
                },
                {
                    title: "Assignment 3",
                    dueDate: "2021-03-06",
                    worth: 15,
                    type: "Assignment",
                },
                {
                    title: "Assignment 4",
                    dueDate: "2021-03-20",
                    worth: 15,
                    type: "Assignment",
                },
                {
                    title: "Assignment 5",
                    dueDate: "2021-04-03",
                    worth: 15,
                    type: "Assignment",
                },
                {
                    title: "Assignment 6",
                    dueDate: "2021-04-10",
                    worth: 15,
                    type: "Assignment",
                },
                {
                    title: "Assignment 7",
                    dueDate: "2021-04-17",
                    worth: 15,
                    type: "Assignment",
                },
                {
                    title: "Term Test 1",
                    dueDate: "null",
                    worth: 25,
                    type: "Exam",
                },
                {
                    title: "Term Test 2",
                    dueDate: "null",
                    worth: 25,
                    type: "Exam",
                },
                {
                    title: "Final Assessment",
                    dueDate: "null",
                    worth: 35,
                    type: "Exam",
                },
            ],
        },
    },
};

export const aps360CourseInfo = {
    message: "Course parsed",
    course: {
        result: {
            university: "University of Toronto",
            title: "Applied Fundamentals of Deep Learning",
            courseCode: "APS360H1",
            department: "APS",
            code: 360,
            year: 2022,
            semester: "Fall",
            tasks: [
                {
                    title: "Lab 1",
                    dueDate: "2022-10-07",
                    worth: 1,
                    type: "asignment",
                },
                {
                    title: "Lab 2",
                    dueDate: "2022-10-14",
                    worth: 2,
                    type: "assignment",
                },
                {
                    title: "Lab 3",
                    dueDate: "2022-10-21",
                    worth: 3,
                    type: "assignment",
                },
                {
                    title: "Lab 4",
                    dueDate: "2022-10-28",
                    worth: 2,
                    type: "assignment",
                },
                {
                    title: "Lab 5",
                    dueDate: "2022-11-04",
                    worth: 2,
                    type: "assignment",
                },
                {
                    title: "Team Formation",
                    dueDate: "2022-09-30",
                    worth: 1,
                    type: "assignment",
                },
                {
                    title: "Project Proposal",
                    dueDate: "2022-10-14",
                    worth: 5,
                    type: "assignment",
                },
                {
                    title: "Progress Report",
                    dueDate: "2022-11-04",
                    worth: 5,
                    type: "assignment",
                },
                {
                    title: "Project Presentation",
                    dueDate: "2022-11-25",
                    worth: 10,
                    type: "assignment",
                },
                {
                    title: "Final Deliverable",
                    dueDate: "2022-12-02",
                    worth: 20,
                    type: "assignment",
                },
                {
                    title: "Midterm",
                    dueDate: "2022-10-21",
                    worth: 15,
                    type: "exam",
                },
                {
                    title: "Final Examination",
                    dueDate: "2022-12-17",
                    worth: 35,
                    type: "exam",
                },
            ],
        },
    },
};
