import React, { useEffect, useState } from "react";
import SyllabyteList from "../components/homePage/syllabyteList";
import AboutTask from "../components/homePage/aboutTask";
import StatHavenLogo from "../images/StatHavenLogo.png";
import CalendarLogo from "../images/CalendarLogo.png";
import { SelectedTaskProvider } from "../contexts/selectedTaskContext";
import { useNavigate } from "react-router-dom";
import "../styles/App.css";
import "../styles/ToDo.css";

const SyllabyteHomePage = ({ todo, courseInfo }) => {
    const navigate = useNavigate();

    const taskListInfo = [
        {
            desc: "Important",
            color: "#FF8383",
        },
        {
            desc: "Do if you can",
            color: "#FFBD59",
        },
        {
            desc: "Keep in mind",
            color: "#58D7FF",
        },
    ];

    return (
        <>
            <div className="main-list">
                <SelectedTaskProvider>
                    <div className="list-frame">
                        <SyllabyteList todo={todo} courseInfo={courseInfo} />
                        <div className="log-out">
                            <a
                                href="/"
                                onClick={() => {
                                    localStorage.removeItem("authTokens");
                                    localStorage.removeItem("user");
                                }}
                            >
                                logout
                            </a>
                        </div>
                    </div>
                    <div className="side-frame">
                        <div className="filter-box">
                            {taskListInfo.map((task) => (
                                <div
                                    key={task.color}
                                    className="filter-box-row-wrapper"
                                >
                                    <div key={task.desc} className="filter">
                                        {task.desc}
                                    </div>
                                    <div
                                        className="filter-box-square"
                                        style={{ backgroundColor: task.color }}
                                    />
                                </div>
                            ))}
                        </div>
                        <AboutTask />
                        <div className="feature-buttons">
                            <div
                                className="feature-button-wrapper"
                                style={{ backgroundColor: "#f76161" }}
                            >
                                <button onClick={() => navigate("/stathaven")}>
                                    <div className="statHavenLogo">
                                        <img
                                            src={StatHavenLogo}
                                            alt="StatHaven Logo"
                                            width="60%"
                                        />
                                    </div>
                                    <div id="stathaven-font">StatHaven</div>
                                </button>
                            </div>
                            <div
                                className="feature-button-wrapper"
                                style={{ backgroundColor: "#6C88EA" }}
                            >
                                <button
                                    onClick={() =>
                                        navigate("/syllabyte-calendar")
                                    }
                                >
                                    <div className="statHavenLogo">
                                        <img
                                            src={CalendarLogo}
                                            alt="Calendar Logo"
                                            width="60%"
                                        />
                                    </div>
                                    <div id="stathaven-font">Calendar</div>
                                </button>
                            </div>
                        </div>
                        {/* <SpotifyEmbed /> */}
                        {/* <Timer /> */}
                    </div>
                </SelectedTaskProvider>
            </div>
        </>
    );
};

export default SyllabyteHomePage;
