import React, { useEffect, useState } from "react";
import { Modal, Text, Title, Button, Pill } from "@mantine/core";
import { IconShare } from "@tabler/icons-react";
import "../../styles/CoursePreviewModal.css";
import { showCustomNotification } from "../../utils/notification";
import HeatMap from "../heatmap";
import TaskTable from "./taskTable";
import { alertModal } from "../../utils/alertModal";
import { SEMESTERS } from "../../constants/courseConstants";
import taskService from "../../api/taskService";
import { Loader } from "@mantine/core";

const CoursePreviewModal = ({ course, onClose }) => {
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(true);

    const defaultSemester = { name: "Unknown", color: "grey" };

    const getSemesterOrDefault = (key) => {
        return SEMESTERS.get(key) || defaultSemester;
    };

    const getTasks = async () => {
        const response = await taskService.getAllTasksForCourseDB(course.id);
        setTasks(response.data);
        setLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        getTasks();
    }, [course]);

    const handleShare = () => {
        if (course.share_id) {
            const copyToClipboard = () => {
                const shareUrl = `${window.location.origin}/courses/public/${course.share_id}`;
                navigator.clipboard
                    .writeText(shareUrl)
                    .then(() => {
                        showCustomNotification({
                            title: "Share Link Copied",
                            message: `Share link copied to clipboard! url: ${shareUrl}`,
                            color: "green",
                        });
                    })
                    .catch((err) => {
                        showCustomNotification({
                            title: "Share Link Copy Failed",
                            message: "Failed to copy: " + err,
                            color: "red",
                        });
                    });
            };

            // check if the course is public
            if (!course.is_public) {
                // show an alert and verify if the user still wants to share
                alertModal(
                    "This Course is Private!",
                    "This course is currently private. Sharing this link will " +
                        "allow anyone with the link to see the contents of this course. " +
                        "Are you sure you want to proceed?",
                    copyToClipboard,
                    () => {},
                    "Copy Link",
                );
            } else {
                copyToClipboard();
            }
        } else {
            showCustomNotification({
                title: "No Shareable Link",
                message: "This course does not have a shareable link.",
                color: "red",
            });
        }
    };

    // Function to calculate responsive font size
    const calculateFontSize = (title) => {
        const baseSize = 72;
        const minSize = 24;
        const scaleFactor = 0.1;

        const newSize = baseSize - title.length * scaleFactor;
        return Math.max(newSize, minSize);
    };

    if (!course) return null;

    return loading ? (
        <Loader>
            <Text>Loading...</Text>
        </Loader>
    ) : (
        <Modal
            opened={!!course}
            onClose={onClose}
            withCloseButton
            size="xl"
            title={
                <div>
                    <Title
                        order={1}
                        style={{
                            fontSize: `${calculateFontSize(course.title)}px`,
                            fontWeight: "bold",
                            lineHeight: 1.2,
                            wordBreak: "break-word",
                        }}
                    >
                        {course.title}
                    </Title>
                    <div className="course-preview-subtitles">
                        <Text
                            size="lg"
                            c="dimmed"
                        >{`${course.dept} ${course.code}`}</Text>
                        <Text
                            size="lg"
                            weight={500}
                            c={getSemesterOrDefault(course.semester).color}
                            mb="md"
                        >
                            {getSemesterOrDefault(course.semester).name}{" "}
                            {course.year}
                        </Text>{" "}
                    </div>
                    <div className="course-preview-subtitles">
                        <div className="course-preview-about-pills">
                            {course.categories !== ""
                                ? course.categories
                                      .split("$")
                                      .map((category) => (
                                          <Pill key={category}>{category}</Pill>
                                      ))
                                : null}
                        </div>
                        <div className="course-preview-share-button">
                            <Button
                                leftSection={<IconShare size="1rem" />}
                                onClick={handleShare}
                                variant="light"
                                size="xs"
                            >
                                Share
                            </Button>
                        </div>
                    </div>
                </div>
            }
            styles={{
                modal: {
                    padding: "10px",
                    // DOESNT WORK
                    border: "10px dashed #00ff00",
                },
                title: { marginBottom: "0px", width: "95%" },
            }}
        >
            <HeatMap
                tasks={tasks}
                semester_info={{
                    semester: course.semester,
                    year: course.year,
                }}
                chartType="weightedTasks"
            />
            <TaskTable
                tasks={tasks}
                tableType="assignment"
                showGrades={false}
            />
            <TaskTable tasks={tasks} tableType="exam" showGrades={false} />
        </Modal>
    );
};

export default CoursePreviewModal;
