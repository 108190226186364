import React from "react";
import { useEffect, useState, useContext } from "react";
import "../styles/ConfidenceSlider.css";
import { Slider } from "@mantine/core";
import { AuthContext } from "../contexts/authContext";
import { UserContext } from "../contexts/userContext";
import { API_URL } from "../constants";

const getConf = async (props, type, id) => {
    let url;
    if (props.type === "user") {
        url = API_URL + "api/userInfo";
    } else if (props.type === "course") {
        url = API_URL + "api/user/course/" + props.course_id;
    } else if (props.type === "task") {
        url = API_URL + "api/task/" + props.task_id;
    }

    const response = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization:
                "Bearer " +
                JSON.parse(localStorage.getItem("authTokens")).access,
        },
    });
    const data = await response.json();

    if (props.type === "task") {
        return data.confidence;
    }
    if (type === "exam") {
        if (props.type === "user") {
            return data.user.exam_confidence;
        }
        return data.exam_confidence;
    } else {
        if (props.type === "user") {
            return data.user.assignment_confidence;
        }
        return data.assignment_confidence;
    }
};

const ConfidenceSlider = (props) => {
    const [position, setPosition] = useState(50);
    const { user_id } = useContext(AuthContext);
    const updateUserContext = useContext(UserContext);

    useEffect(() => {
        setPosition(props.confidence);
    }, []);

    useEffect(() => {
        if (props.type === "task") {
            getConf(props, "exam", user_id).then((data) => {
                setPosition(data);
            });
        }
    }, [props.task_id]);

    useEffect(() => {
        if (props.type == "course") {
            setPosition(props.confidence);
        }
    }, [props.course_id, props.confidence]);

    return (
        <div className="slider">
            <div className="slider-name">{props.name}</div>
            <div
                className="slider-bar"
                style={{
                    backgroundColor:
                        props.name === "Exam Confidence"
                            ? "#BBABFB"
                            : props.type == "task"
                              ? "#fff"
                              : "#72CCFF",
                }}
            >
                <Slider
                    aria-label="Confidence Slider"
                    color="#5F5F5F"
                    defaultValue={50}
                    value={position}
                    onChange={setPosition}
                    onChangeEnd={async (newValue) => {
                        let response = null;

                        let url;

                        if (props.type === "user") {
                            url = API_URL + "api/user/update";
                        } else if (props.type === "course") {
                            url =
                                API_URL +
                                "api/course/" +
                                props.course_id +
                                "/update";
                        } else if (props.type === "task") {
                            url =
                                API_URL +
                                "api/task/" +
                                props.task_id +
                                "/update";
                        }
                        if (props.name === "Exam Confidence") {
                            response = fetch(url, {
                                method: "PATCH",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization:
                                        "Bearer " +
                                        JSON.parse(
                                            localStorage.getItem("authTokens"),
                                        ).access,
                                },
                                body: JSON.stringify({
                                    exam_confidence: newValue,
                                }),
                            });
                        } else if (props.name === "Assignment Confidence") {
                            response = fetch(url, {
                                method: "PATCH",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization:
                                        "Bearer " +
                                        JSON.parse(
                                            localStorage.getItem("authTokens"),
                                        ).access,
                                },
                                body: JSON.stringify({
                                    assignment_confidence: newValue,
                                }),
                            });
                        } else if (props.name === "") {
                            response = fetch(url, {
                                method: "PATCH",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization:
                                        "Bearer " +
                                        JSON.parse(
                                            localStorage.getItem("authTokens"),
                                        ).access,
                                },
                                body: JSON.stringify({
                                    confidence: newValue,
                                }),
                            });
                        } else {
                            console.error(
                                "Error: Invalid Confidence Slider Name",
                            );
                        }
                        if (response) {
                            response = await response;
                        }

                        if (props.type == "user" && response.status === 200) {
                            updateUserContext();
                        }
                    }}
                />
            </div>
        </div>
    );
};

export default ConfidenceSlider;
