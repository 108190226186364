import React, { useEffect, useState } from "react";
import SettingsButton from "./settingsButton";
import "../styles/profile.css";
import SyllabyteLogo from "../images/SyllabyteLogo.png";

const Profile = ({ user }) => {
    const [userInfo, setUserInfo] = useState({});

    useEffect(() => {
        setUserInfo(user);
    }, [user]);

    return (
        <div className="profile">
            <a className="profile-pic" href="/home">
                <img src={SyllabyteLogo} alt="Avatar" />
            </a>
            <div className="profile-name">{userInfo.name}</div>
            <SettingsButton />
        </div>
    );
};

export default Profile;
