import React, { useEffect, useState } from "react";
import { API_URL } from "../constants";
import { useParams } from "react-router-dom";
import "../styles/register/Verify.css";

const Verify = () => {
    const { uidb64, token } = useParams();
    const [isVerified, setIsVerified] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        const response = fetch(API_URL + `api/verify-email/${uidb64}/${token}`);
        response.then(async (response) => {
            const responseJson = await response.json();
            if (response.status === 200) {
                setIsVerified(true);
            } else {
                setErrorMessage(
                    "Failed to verify email " + responseJson.message,
                );
            }
        });
    }, []);
    return (
        <div>
            {isVerified ? (
                <div className="verification-wrapper">
                    <h3>Email verified!</h3>
                    <a href="/"> Go to your Syllabyte</a>
                </div>
            ) : (
                <div className="verification-wrapper">
                    <h3>{errorMessage}</h3>
                    <p>
                        {" "}
                        Try signing up again at{" "}
                        <a href="/signup">www.mysyllabyte.com/signup</a>
                    </p>
                </div>
            )}
        </div>
    );
};

export default Verify;
