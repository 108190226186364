import React, { useEffect, useState } from "react";
import "../../styles/RegisterPage.css";
import { API_URL } from "../../constants";

const PersonalInfo = () => {
    const [universities, setUniversities] = useState([]);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const getUniversities = async () => {
        const response = await fetch(API_URL + "api/university/list");

        if (response.status !== 200) {
            console.log("Error fetching universities");
            return;
        }

        const data = await response.json();
        setUniversities(data);
    };

    useEffect(() => {
        getUniversities();
    }, []);

    useEffect(() => {
        // check if the passwords match
        if (password !== confirmPassword) {
            document.getElementById("password-unmatch-error").style.display =
                "block";
        } else {
            document.getElementById("password-unmatch-error").style.display =
                "none";
        }

        // check if the password meets the requirements
        if (
            password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/) === null &&
            password.length > 0
        ) {
            document.getElementById("password-requirements").style.display =
                "block";
        } else {
            document.getElementById("password-requirements").style.display =
                "none";
        }
    }, [password, confirmPassword]);

    return (
        <>
            <form className="form-container">
                <div className="register-inputs">
                    <label>Full name </label>
                    <input
                        className="register-username"
                        type="text"
                        name="fullname"
                        placeholder="Name"
                        required
                    />
                    <label>University</label>
                    <p className="sublabel">
                        This can&apos;t be changed later! We do this to give you
                        courses that are relevant to your university.
                    </p>
                    <select
                        className="register-university"
                        name="university"
                        required
                    >
                        {universities.map((university) => (
                            <option key={university.id} value={university.id}>
                                {university.name}
                            </option>
                        ))}
                    </select>
                    <p className="sublabel">
                        Don&apos;t see your university? Select &quot;Other&quot;
                        while we work on adding it.{" "}
                        <a
                            href="https://forms.fillout.com/t/7hGubb7Zpmus"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Request support for your university
                        </a>
                    </p>

                    <label>University email</label>
                    <p className="sublabel">
                        This can&apos;t be changed later.
                    </p>
                    <input
                        className="register-email"
                        type="text"
                        name="email"
                        pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                        title="Invalid email address"
                        placeholder="Email"
                        required
                    />
                    <label>Password </label>
                    <input
                        className="register-password"
                        id="register-password-input"
                        type="password"
                        name="password"
                        placeholder="Password"
                        pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                        required
                        onChange={(event) => setPassword(event.target.value)}
                    />
                    <label>Confirm password </label>
                    <input
                        className="register-password"
                        id="register-password-confirm"
                        type="password"
                        name="password"
                        placeholder="Confirm Password"
                        required
                        onChange={(event) =>
                            setConfirmPassword(event.target.value)
                        }
                    />
                    <span
                        className="password-error"
                        id="password-unmatch-error"
                    >
                        Passwords do not match
                    </span>
                    <span className="password-error" id="password-requirements">
                        Password must contain at least 8 characters, including
                        upper and lower case letters and numbers
                    </span>
                </div>
                <div className="register-submit">
                    <button
                        type="submit"
                        onClick={async (event) => {
                            event.preventDefault();

                            // lets replace the submit button with a loading spinner while we await the response
                            document.querySelector(
                                ".register-submit",
                            ).innerHTML = '<div class="loading-spinner"></div>';

                            // make sure the input's are all valid
                            const inputs = document.querySelectorAll("input");
                            let valid = true;
                            inputs.forEach((input) => {
                                if (!input.checkValidity()) {
                                    valid = false;
                                }
                            });

                            if (!valid || password !== confirmPassword) {
                                alert("Please fill out all fields correctly");
                                window.location.reload();
                                return;
                            }

                            // get the input values
                            const fullname =
                                document.querySelector(
                                    ".register-username",
                                ).value;
                            const university = document.querySelector(
                                ".register-university",
                            ).value;
                            const email =
                                document.querySelector(".register-email").value;

                            // send the data to the server
                            const response = await fetch(
                                API_URL + "api/register",
                                {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify({
                                        name: fullname,
                                        email,
                                        password,
                                        university,
                                    }),
                                },
                            );

                            const data = await response.json();

                            if (
                                response.status === 200 ||
                                response.status === 201
                            ) {
                                // let user know they have registered with a popup
                                alert(
                                    "Check your email to verify your account",
                                );

                                // redirect to the home page
                                window.location.href = "/login";
                            } else {
                                alert(`There was an error: ${data.message}`);
                                window.location.reload();
                            }
                        }}
                    >
                        Submit
                    </button>
                </div>
            </form>
        </>
    );
};

export default PersonalInfo;
