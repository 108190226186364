// CreateCourse.js
import React from "react";
import CourseForm from "../components/createCourse/CourseForm";
import TaskSection from "../components/createCourse/taskSection";
import SyllabusUploader from "../components/createCourse/SyllabusUploader";
import {
    Box,
    Group,
    Button,
    Text,
    Switch,
    LoadingOverlay,
} from "@mantine/core";
import { useCreateCourse } from "../hooks/useCreateCourse";
import { EXAM, ASSIGNMENT } from "../constants/courseConstants";
import { useFeatureFlags } from "../contexts/featureFlagContext";

export default function CreateCourse({ syllabusFile }) {
    const {
        form,
        totalWorth,
        handleSubmit,
        handleSyllabusUpload,
        fillDummyValues,
        clearForm,
        loaderVisible,
    } = useCreateCourse(syllabusFile);

    const featureFlags = useFeatureFlags();

    return (
        <Box mx="auto" pos="relative">
            <LoadingOverlay
                visible={loaderVisible}
                zIndex={1000}
                overlayProps={{ radius: "sm", blur: 2 }}
            />
            <form onSubmit={form.onSubmit(handleSubmit)}>
                <CourseForm form={form} />

                <TaskSection
                    form={form}
                    taskType={EXAM}
                    description={`Timed Assessments - Quizzes, Midterms, Finals, etc.
                    Leave the due date blank for unreleased exams.`}
                />

                <TaskSection
                    form={form}
                    taskType={ASSIGNMENT}
                    description="Deliverables or tasks that are worth marks - 
                    Homework, Readings, Problem Sets, Labs, Projects, etc."
                />

                {featureFlags.parseSyllabus && (
                    <>
                        <br />
                        {/* Pass syllabus to SyllabusUploader to display 
                        previously selected file from courseSelection page */}
                        <SyllabusUploader
                            onUpload={handleSyllabusUpload}
                            initialFile={syllabusFile}
                        />
                    </>
                )}

                <br />

                <Switch
                    label="Publish this course? (make visible to everyone in your university!)"
                    {...form.getInputProps("is_public")}
                />

                <Group justify="space-between" mt="xl" mb="md">
                    <Group>
                        <Button
                            onClick={fillDummyValues}
                            disabled={loaderVisible}
                        >
                            Fill With Dummy Values!
                        </Button>
                        <Button
                            color="red.5"
                            onClick={clearForm}
                            disabled={loaderVisible}
                        >
                            Clear Form
                        </Button>
                    </Group>

                    <Group justify="flex-end">
                        <Text>
                            Total Worth:
                            <span
                                style={{
                                    color: totalWorth === 100 ? "green" : "red",
                                }}
                            >
                                {" " + totalWorth}%
                            </span>
                        </Text>
                        <Button type="submit" loading={loaderVisible}>
                            Create & Enrol
                        </Button>
                    </Group>
                </Group>
            </form>
        </Box>
    );
}
