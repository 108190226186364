import React from "react";
import CourseHero from "./courseHero";
import "../../styles/landingPage/Hero.css";
import hero2 from "../../images/landingPage/hero2.png";
import hero3 from "../../images/landingPage/hero3.png";
import hero4 from "../../images/landingPage/hero4.png";
import hero5 from "../../images/landingPage/hero5.png";

const Hero = () => {
    return (
        <div className="hero">
            <CourseHero speed={26000} images={[hero3, hero3, hero3, hero3]} />
            <CourseHero speed={27000} images={[hero5, hero5, hero5, hero5]} />
            <CourseHero speed={32000} images={[hero4, hero4, hero4, hero4]} />
            <CourseHero speed={30000} images={[hero2, hero2]} />
        </div>
    );
};

export default Hero;
