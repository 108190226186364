import React, { useState, useEffect, useContext } from "react";
import "../styles/Courses.css";
import {
    DndContext,
    closestCenter,
    useSensors,
    useSensor,
    PointerSensor,
} from "@dnd-kit/core";
import {
    SortableContext,
    arrayMove,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import SortableCourse from "./sortableCourse.js";
import { ChangeContext } from "../contexts/changeContext.js";
import { API_URL } from "../constants.js";

const Courses = ({ courseInfo, coursesLoaded }) => {
    const [items, setItems] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isDragging, setIsDragging] = useState(false);

    const changeInfo = useContext(ChangeContext);

    useEffect(() => {
        setItems(courseInfo);
    }, [courseInfo]);

    useEffect(() => {
        setIsLoaded(coursesLoaded);
    }, [coursesLoaded]);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 8,
            },
        }),
    );

    return (
        <DndContext
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            onDragStart={() => setIsDragging(true)}
            sensors={sensors}
        >
            <SortableContext
                items={items}
                strategy={verticalListSortingStrategy}
            >
                <div className="course-button">
                    {!isLoaded ? (
                        <div className="course-button-none">
                            Loading Courses...
                        </div>
                    ) : items.length === 0 ? (
                        <div className="course-button-none">
                            No courses added yet!
                        </div>
                    ) : (
                        items
                            .sort((a, b) => a.priority - b.priority)
                            .map((item) => (
                                <SortableCourse
                                    key={item.id}
                                    id={item.id}
                                    priority={item.priority}
                                    course_info_full={item.course_info_full}
                                    isDragging={isDragging}
                                />
                            ))
                    )}
                </div>
            </SortableContext>
        </DndContext>
    );

    function handleDragEnd(event) {
        const { active, over } = event;
        if (active.id !== over.id) {
            setItems((items) => {
                const oldIndex = items.findIndex(
                    (item) => item.id === active.id,
                );
                const newIndex = items.findIndex((item) => item.id === over.id);

                const newarr = arrayMove(items, oldIndex, newIndex);
                // set all priorities to be the index of the item in the array
                const updatePriorities = async () => {
                    for (let i = 0; i < newarr.length; i++) {
                        newarr[i].priority = i + 1;

                        // update the priority in the database
                        fetch(
                            API_URL + "api/course/" + newarr[i].id + "/update",
                            {
                                method: "PATCH",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization:
                                        "Bearer " +
                                        JSON.parse(
                                            localStorage.getItem("authTokens"),
                                        ).access,
                                },
                                body: JSON.stringify({
                                    priority: i + 1,
                                }),
                            },
                        )
                            .then((response) => response.json())
                            .catch((error) => {
                                console.error("Error:", error);
                            });
                    }
                };

                updatePriorities().then(changeInfo);
                return newarr;
            });
        }
        setIsDragging(false);
    }
};

export default Courses;
