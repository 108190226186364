import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import "../styles/StatHaven.css";

const HeatMap = ({ tasks, semester_info, chartType }) => {
    const [numberOfTasksWorkloadData, setNumberOfTasksWorkloadData] = useState(
        [],
    );
    const [weightedWorkloadData, setWeightedWorkloadData] = useState([]);
    const [height, setHeight] = useState(0);
    const [semStart, setSemStart] = useState(null);
    const [semEnd, setSemEnd] = useState(null);

    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const calculateHeight = () => {
        // we want to make the heatmap boxes to be squares
        // so calculate the height needed based on screen size
        const screenWidth =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
        const height = screenWidth * 0.31;
        setHeight(height);
    };

    useEffect(() => {
        if (semester_info !== null) {
            const { year, semester } = semester_info;

            const semesterDates = getSemesterDates(year, semester);
            if (semesterDates) {
                setSemStart(semesterDates.start);
                setSemEnd(semesterDates.end);
            }
        }
    }, [semester_info]);

    useEffect(() => {
        if (semStart !== null && semEnd !== null && tasks) {
            calculateHeight();
            calculateWorkload(tasks, semStart, semEnd);
        }
    }, [semStart, semEnd, tasks]);

    const calculateWorkload = async (tasks, semester_info) => {
        if (!tasks) {
            console.error("No tasks provided or tasks array is empty");
            return;
        }

        const totalDays = Math.ceil(
            (semEnd - semStart) / (1000 * 60 * 60 * 24),
        );
        let maxNumberOfTasks = 0;
        let minNumberOfTasks = 0;
        let minWeightedTasks = 0;
        let maxWeightedTasks = 0;
        // get number of tasks per day of the semester
        const taskDates = new Map();
        const weightedTaskDates = new Map();

        for (const task of tasks) {
            if (!task.due_date) {
                continue;
            }

            const taskDueDate = new Date(task.due_date + "T00:00:00Z");
            const taskStartDate = task.starts_at
                ? new Date(task.starts_at)
                : new Date(
                      taskDueDate.getTime() -
                          (task.time_needed || 0) * 24 * 60 * 60 * 1000,
                  );

            const daysBetween = Math.max(
                0,
                Math.ceil(
                    (taskDueDate - taskStartDate) / (1000 * 60 * 60 * 24),
                ),
            );

            // get dates between start and end date
            for (let i = 0; i <= daysBetween; i++) {
                const date = new Date(taskStartDate);
                date.setDate(date.getDate() + i);
                const dateString = date.toDateString();

                // Update taskDates
                const currentTasks = (taskDates.get(dateString) || 0) + 1;
                taskDates.set(dateString, currentTasks);
                maxNumberOfTasks = Math.max(maxNumberOfTasks, currentTasks);
                minNumberOfTasks = Math.min(minNumberOfTasks, currentTasks);

                // Update weightedTaskDates
                const taskWorth = task.worth || 0;
                const currentWeight =
                    (weightedTaskDates.get(dateString) || 0) + taskWorth;
                weightedTaskDates.set(dateString, currentWeight);
                maxWeightedTasks = Math.max(maxWeightedTasks, currentWeight);
                minWeightedTasks = Math.min(minWeightedTasks, currentWeight);
            }
        }

        // Fill in the rest of the days with 0 tasks
        for (let i = 0; i < totalDays; i++) {
            const date = new Date(semStart);
            date.setDate(date.getDate() + i);
            const dateString = date.toDateString();

            if (!taskDates.has(dateString)) {
                taskDates.set(dateString, 0);
            }
            if (!weightedTaskDates.has(dateString)) {
                weightedTaskDates.set(dateString, 0);
            }
        }

        // Create workload data
        const numberOfTasksWorkloadData = createWorkloadData(
            semStart,
            totalDays,
            taskDates,
            minNumberOfTasks,
            maxNumberOfTasks,
        );
        const weightedWorkloadData = createWorkloadData(
            semStart,
            totalDays,
            weightedTaskDates,
            minWeightedTasks,
            maxWeightedTasks,
        );

        setNumberOfTasksWorkloadData(numberOfTasksWorkloadData);
        setWeightedWorkloadData(weightedWorkloadData);
    };

    // This function is a bit complex because of the mappings between the day, date and weeks
    // The comments attempt at explaining the logic, but its mostly mapping the x and y axis to be
    // the correct dates and weeks
    const createWorkloadData = (
        start,
        totalDays,
        dataMap,
        minValue,
        maxValue,
    ) => {
        // These are the usual days of the week, where the index is
        // in line with what .getDay() returns
        const daysOfWeek = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
        ];

        // We use a startDayIndex since each week starts on a different day
        // Since our heatmap isn't built off a calendar, we need to manually set this start day
        const startDayIndex = start?.getDay();

        // Now we need to rearrange the days of the week to start on the correct day
        const rearrangedDays = [
            ...daysOfWeek.slice(startDayIndex),
            ...daysOfWeek.slice(0, startDayIndex),
        ];

        // Reverse the order of the days so that the heatmap starts on the correct day
        rearrangedDays.reverse();

        // Initialize workloadData with rearranged days
        const workloadData = rearrangedDays.map((day) => ({
            name: day,
            data: [],
        }));

        // Iterate over each day in the semester and map the data for each cell
        for (let i = 0; i < totalDays; i++) {
            const date = new Date(start);
            date.setDate(date?.getDate() + i);

            const dayOfWeek = date?.getDay();
            const week = Math.floor(i / 7) + 1;
            const dateString = date.toDateString();

            const value = dataMap.get(dateString) || 0;

            const normalizedValue =
                maxValue === minValue
                    ? 0
                    : (value - minValue) / (maxValue - minValue);

            // Map the data to the correct day and week
            const dayIndex = rearrangedDays.indexOf(daysOfWeek[dayOfWeek]);
            workloadData[dayIndex].data.push({
                x: `W ${week}`,
                y: normalizedValue,
                description: { date: dateString, value: value },
            });
        }

        return workloadData;
    };

    const getSemesterDates = (year, semester) => {
        let start;
        let end;

        switch (semester) {
            case 1:
                start = new Date(year, 8, 1); // September 1st
                end = new Date(year, 11, 31); // December 31st
                break;
            case 2:
                start = new Date(year, 0, 1); // January 1st of next year
                end = new Date(year, 3, 30); // April 30th of next year
                break;
            case 3:
                start = new Date(year, 4, 1); // May 1st
                end = new Date(year, 7, 31); // August 31st
                break;
            default:
                console.error("Invalid semester");
                return null;
        }
        return { start, end };
    };

    const getChartOptions = (title, valueType) => ({
        chart: {
            type: "heatmap",
            id:
                valueType === "Number of Tasks"
                    ? "numOfTasks"
                    : "weightedTasks",
            fontFamily: "MyOutfit-L, sans-serif",
            animations: {
                enabled: false,
            },
        },
        plotOptions: {
            heatmap: {
                colorScale: {
                    ranges: [
                        { from: 0, to: 0, color: "#53CD3F", name: "no tasks" },
                        { from: 0.001, to: 0.4, color: "#128FD9", name: "low" },
                        {
                            from: 0.4001,
                            to: 0.7,
                            color: "#FAB91B",
                            name: "medium",
                        },
                        { from: 0.7001, to: 1, color: "#FF2121", name: "high" },
                    ],
                },
                enableShades: true,
                shadeIntensity: 0.7,
            },
        },
        dataLabels: { enabled: false },
        xaxis: {
            type: "category",
            categories: [
                "W 1",
                "W 2",
                "W 3",
                "W 4",
                "W 5",
                "W 6",
                "W 7",
                "W 8",
                "W 9",
                "W 10",
                "W 11",
                "W 12",
                "W 13",
                "W 14",
                "W 15",
                "W 16",
                "W 17",
            ],
            position: "top",
            group: {
                style: {
                    fontSize: "10px",
                    fontWeight: 700,
                    colors: "#000000",
                },
                groups: [
                    {
                        title: semStart ? months[semStart.getMonth()] : "",
                        cols: 4,
                    },
                    {
                        title: semStart
                            ? months[(semStart.getMonth() + 1) % 12]
                            : "",
                        cols: 4,
                    },
                    {
                        title: semStart
                            ? months[(semStart.getMonth() + 2) % 12]
                            : "",
                        cols: 4,
                    },
                    {
                        title: semStart
                            ? months[(semStart.getMonth() + 3) % 12]
                            : "",
                        cols: 4,
                    },
                ],
            },
        },
        yaxis: {
            type: "category",
            categories: numberOfTasksWorkloadData.map((day) => day.name),
        },
        title: { text: title },
        legend: {
            show: true,
            position: "bottom",
            onItemHover: { highlightDataSeries: false },
        },
        tooltip: {
            custom: function ({ seriesIndex, dataPointIndex, w }) {
                const data =
                    w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                return `
                    <div class="arrow_box">
                        <span>${data.description.date}</span><br>
                        <span>
                            ${valueType}: ${data.description.value}
                        </span>
                    </div>
                `;
            },
        },
        grid: { show: false },
    });

    return chartType === "numOfTasks" ? (
        numberOfTasksWorkloadData.length > 0 ? (
            <Chart
                options={getChartOptions(
                    "Workload by Number of Tasks",
                    "Number of Tasks",
                )}
                series={numberOfTasksWorkloadData}
                type="heatmap"
                height={height}
            />
        ) : (
            <div>Loading Charts...</div>
        )
    ) : chartType === "weightedTasks" ? (
        weightedWorkloadData.length > 0 ? (
            <Chart
                options={getChartOptions(
                    "Workload by Worth of Tasks",
                    "Total worth",
                )}
                series={weightedWorkloadData}
                type="heatmap"
                height={height}
            />
        ) : (
            <div>Loading Charts...</div>
        )
    ) : null;
};

export default HeatMap;
