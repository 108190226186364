import React from "react";
import "../styles/AddNewCourse.css";
import { useNavigate } from "react-router-dom";
import AddButton from "../images/AddButton.png";

const AddNewCourse = () => {
    const navigate = useNavigate();
    return (
        <div
            className="add-course-button"
            onClick={() => {
                navigate("/addcourse");
            }}
        >
            <div className="button-left">Add/Drop Course</div>
            <div className="button-right">
                <img src={AddButton} alt="Add Button" />
            </div>
        </div>
    );
};

export default AddNewCourse;
