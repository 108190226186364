import { randomId } from "@mantine/hooks";
import {
    SEMESTERS,
    CreateCoursePlaceholderInfo,
    SemesterNameOptions,
} from "../constants/courseConstants";
import { getTodaysDate } from "./generalUtils";

export function calculateListTotalWorth(list) {
    return list.reduce((acc, task) => acc + Number(task.worth), 0);
}

export function calculateTotalWorth(examTasks, assignmentTasks) {
    return (
        calculateListTotalWorth(examTasks) +
        calculateListTotalWorth(assignmentTasks)
    );
}

/**
 * Formats a date object into a string representation of the date in the format "YYYY-MM-DD".
 * If the input date is null, returns null.
 *
 * @param {Date} date - The date object to be formatted.
 * @return {string|null} - The formatted date string or null if the input date is null.
 */

export function validateDueDate(dueDate, values) {
    if (!dueDate) return true;
    return (
        SEMESTERS.get(
            SemesterNameOptions.get(values.semester),
        ).monthRange.includes(dueDate.getMonth()) &&
        dueDate.getFullYear() === values.year.getFullYear()
    );
}

export function getInitialValues() {
    return {
        title: CreateCoursePlaceholderInfo.title,
        department: CreateCoursePlaceholderInfo.department,
        code: CreateCoursePlaceholderInfo.code,
        year: new Date(CreateCoursePlaceholderInfo.year, 0, 1),
        semester: CreateCoursePlaceholderInfo.semester,
        examTasks: CreateCoursePlaceholderInfo.examTasks.map((task) => ({
            ...task,
            dueDate: new Date(task.dueDate),
            key: randomId(),
        })),
        assignmentTasks: CreateCoursePlaceholderInfo.assignmentTasks.map(
            (task) => ({
                ...task,
                dueDate: new Date(task.dueDate),
                key: randomId(),
            }),
        ),
    };
}
