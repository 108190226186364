import React from "react";
import { useEffect, useState, useContext } from "react";
import jQuery from "jquery";
import RemoveButton from "../../images/RemoveButton.png";
import PlusButton from "../../images/PlusButton.png";
import { CourseContext } from "../../contexts/courseContext";
import { ChangeContext } from "../../contexts/changeContext";
import { API_URL } from "../../constants";

function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = jQuery.trim(cookies[i]);
            if (cookie.substring(0, name.length + 1) === name + "=") {
                cookieValue = decodeURIComponent(
                    cookie.substring(name.length + 1),
                );
                break;
            }
        }
    }
    return cookieValue;
}

const CourseSelectionButton = ({ course, num_courses }) => {
    const [status, setStatus] = useState("");

    useEffect(() => {
        setStatus(course.added);
    }, [course.added]);

    const api = useContext(CourseContext);
    const updateTodo = useContext(ChangeContext);

    const handleOnClick = async () => {
        // CSRF token
        const csrftoken = getCookie("csrftoken");
        const manipulateCourse = async (str) => {
            let type;
            if (str === "add") type = "POST";
            else if (str === "drop") type = "DELETE";

            // Check how many courses the user has
            if (str === "add" && num_courses >= 8) {
                alert("You can only add up to 8 courses!");
                return;
            }

            const response = await fetch(
                API_URL + "api/course/" + str + "/" + course.id,
                {
                    method: type,
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": csrftoken,
                        Authorization:
                            "Bearer " +
                            JSON.parse(localStorage.getItem("authTokens"))
                                .access,
                    },
                },
            );
            const data = await response.json();

            return data.status === "true" ? true : "error";
        };
        let ret_status;
        if (status === false) ret_status = await manipulateCourse("add");
        else ret_status = await manipulateCourse("drop");

        if (ret_status === "error") console.error("error changing course");
        else {
            setStatus(!status);
        }
    };

    if (status === false) {
        return (
            <button
                className="course-selection-button tooltip"
                onClick={async () => {
                    await handleOnClick().then(api).then(updateTodo);
                }}
            >
                <img src={PlusButton} alt="add course" />
                <span className="tooltiptext">Add Course</span>
            </button>
        );
    }
    return (
        <button
            className="course-selection-button tooltip"
            onClick={async () => {
                await handleOnClick().then(api).then(updateTodo);
            }}
        >
            <img src={RemoveButton} alt="drop course" />
            <span className="tooltiptext">Drop Course</span>
        </button>
    );
};

export default CourseSelectionButton;
