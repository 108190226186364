import React from "react";
import "../styles/landingPage/LandingPage.css";
import Header from "../components/landingPage/header";
import Hero from "../components/landingPage/hero";
import Why from "../components/landingPage/why";

const LandingPage = () => {
    return (
        <div className="landing-page">
            <Header />
            <Hero />
            <Why />
            <footer className="Footer">
                <div className="FooterContent">
                    <div className="FooterInfo">
                        <p>
                            Contact us:{" "}
                            <a href="mailto:yoursyllabyte@gmail.com">
                                yoursyllabyte@gmail.com
                            </a>
                        </p>
                        <p>© 2024 Syllabyte. All rights reserved.</p>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default LandingPage;
