import axiosInstance from "./axiosConfig";

/**
 * Service for handling file operations.
 * @namespace fileService
 */
const fileService = {
    /**
     * Uploads a file to the server.
     * @async
     * @memberof fileService
     * @param {File} file - The file to be uploaded.
     * @param {string} fileType - The type of the file. For now only supports "syllabus" or "generic".
     * @return {Promise} The response data from the
     * server.
     * @throws {Error} If an error occurs during the file upload process.
     */
    uploadFile: async (file, fileType) => {
        try {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("file_type", fileType ? fileType : "generic");

            const response = await axiosInstance.post(
                "files/upload/",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    },
};

export default fileService;
