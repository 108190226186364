import React, { useState, useEffect, useContext } from "react";
import "../../styles/CoursePage.css";
import ConfidenceSlider from "../confidenceSlider.js";
import WarningBox from "../general/warningbox";
import { AuthContext } from "../../contexts/authContext";
import { ChangeContext } from "../../contexts/changeContext";
import { API_URL } from "../../constants";
import { showCustomNotification } from "../../utils/notification.js";
import taskService from "../../api/taskService.js";
import { validateUrl } from "../../utils/generalUtils.js";

const TaskInfo = ({ task, addNewTask, courseId, updateTasks }) => {
    const [title, setTitle] = useState("");
    const [dueDate, setDueDate] = useState("");
    const [confidence, setConfidence] = useState(-1);
    const [worth, setWorth] = useState(-1);
    const [timeNeeded, setTimeNeeded] = useState(-1);
    const [changeAnything, setChangeAnything] = useState(false);
    const [userUpdatedTime, setUserUpdatedTime] = useState(false);
    const [externalLink, setExternalLink] = useState("");
    const [taskInfo, setTaskInfo] = useState("assignment");
    const { userId } = useContext(AuthContext);
    const [warningBox, setWarningBox] = useState(false);
    const [warningBoxMessage, setWarningBoxMessage] = useState("");
    const getTodoList = useContext(ChangeContext);

    useEffect(() => {
        if (addNewTask) {
            setTitle("");
            setDueDate("");
            setConfidence(50);
            setWorth("");
            setTimeNeeded("");
            setUserUpdatedTime(false);
            setExternalLink("");
            setChangeAnything(false);
            setWarningBox(false);
        } else {
            setTitle(task.title);

            const date = task.due_date ? new Date(task.due_date) : null;
            // check (< 2001) due to timezone edge case.
            if (date === null || date.getFullYear() <= 2001) {
                setDueDate("Not Set Yet");
            } else {
                setDueDate(task.due_date);
            }
            setConfidence(task.confidence);
            setWorth(task.worth);
            setTimeNeeded(task.time_needed);
            setUserUpdatedTime(task.user_updated_time);
            setExternalLink(task.external_link);
            const category = task.categories.includes("exam")
                ? "exam"
                : "assignment";
            setTaskInfo(category);
            setChangeAnything(false);
            setWarningBox(false);
        }
    }, [task, addNewTask]);

    return (
        <div className="task-info-wrapper">
            <form className="task-info-flex">
                <div className="task-info-input">
                    <label htmlFor="name">Title</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={title}
                        onChange={(e) => {
                            setTitle(e.target.value);
                            setChangeAnything(true);
                        }}
                    />
                </div>
                <div className="task-info-input">
                    <label htmlFor="taskInfo">Task Type</label>
                    <select
                        id="taskInfo"
                        name="taskInfo"
                        value={taskInfo}
                        onChange={(e) => {
                            setTaskInfo(e.target.value);
                            setChangeAnything(true);
                        }}
                    >
                        <option value="assignment">Assignment</option>
                        <option value="exam">Exam</option>
                    </select>
                </div>
                <div className="task-info-input">
                    <label htmlFor="due">Due Date</label>
                    <input
                        type="date"
                        id="due"
                        name="due"
                        value={dueDate}
                        onChange={(e) => {
                            setDueDate(e.target.value);
                            setChangeAnything(true);
                        }}
                    />
                </div>
                <div className="task-info-input">
                    <label htmlFor="worth">Worth</label>
                    <input
                        type="number"
                        id="worth"
                        step="any"
                        min="0"
                        max="100"
                        name="worth"
                        value={worth === 0 ? "" : worth}
                        onChange={(e) => {
                            const val = parseFloat(e.target.value);

                            if (val < 0 || val > 100) {
                                setWarningBoxMessage(
                                    "Please enter a value between 0 and 100",
                                );
                                setWarningBox(true);
                                return;
                            }

                            if (isNaN(val)) {
                                setWorth(0);
                            } else {
                                setWorth(val);
                            }

                            setChangeAnything(true);
                        }}
                    />
                </div>
                {addNewTask ? null : (
                    <div className="task-info-input">
                        <label htmlFor="time_needed">Days Needed</label>
                        <input
                            type="number"
                            id="time_needed"
                            name="time_needed"
                            min="0"
                            value={timeNeeded === 0 ? "" : timeNeeded}
                            onChange={(e) => {
                                const val = parseInt(e.target.value);

                                if (val < 0) {
                                    setWarningBoxMessage(
                                        "Please enter a positive value",
                                    );
                                    setWarningBox(true);
                                    return;
                                }

                                if (isNaN(val)) {
                                    setTimeNeeded(0);
                                } else {
                                    setTimeNeeded(parseInt(e.target.value));
                                }
                                setChangeAnything(true);
                                if (!userUpdatedTime) {
                                    setWarningBoxMessage(
                                        "You have updated the time needed for this task. " +
                                            "This will override the Syllabyte algorithm. ",
                                    );
                                    setWarningBox(true);
                                }
                            }}
                        />
                    </div>
                )}
                <div className="task-info-input">
                    <label htmlFor="external_link">External Link</label>
                    <input
                        type="text"
                        id="external_link"
                        name="external_link"
                        value={externalLink}
                        onChange={(e) => {
                            setExternalLink(e.target.value);
                            setChangeAnything(true);
                        }}
                    />
                </div>
            </form>
            {addNewTask ? (
                <div>
                    <button
                        className="task-info-button"
                        style={{ backgroundColor: "#58C955" }}
                        onClick={async () => {
                            if (changeAnything) {
                                // check if all fields are filled
                                if (
                                    title === "" ||
                                    worth === "" ||
                                    taskInfo === ""
                                ) {
                                    setWarningBoxMessage(
                                        "Please fill out all fields",
                                    );
                                    setWarningBox(true);
                                    return;
                                }

                                if (
                                    externalLink !== "" &&
                                    !validateUrl(externalLink)
                                ) {
                                    setWarningBoxMessage(
                                        "Please enter a valid URL",
                                    );
                                    setWarningBox(true);
                                    return;
                                }

                                setChangeAnything(false);

                                let date = new Date();
                                // handle due date
                                if (dueDate === "") {
                                    date = null;
                                } else {
                                    date = new Date(dueDate);
                                }

                                // Add task to database
                                const res = await taskService.addNewTask({
                                    title: title,
                                    due_date: date,
                                    worth: worth,
                                    categories: taskInfo,
                                    time_needed: timeNeeded,
                                    course_id: courseId,
                                    userId: userId,
                                    external_link: externalLink,
                                });

                                if (res.status === 200) {
                                    updateTasks().then(getTodoList());
                                    showCustomNotification({
                                        title: "Task Added",
                                        message: "Task added successfully",
                                        color: "green",
                                    });
                                }
                            } else {
                                setWarningBoxMessage(
                                    "Please change at least one field",
                                );
                                setWarningBox(true);
                            }
                        }}
                    >
                        {" "}
                        Add{" "}
                    </button>
                </div>
            ) : (
                <div className="task-info-buttons">
                    <button
                        className="task-info-button"
                        style={{ backgroundColor: "#58C955" }}
                        onMouseEnter={(e) => {
                            e.target.style.backgroundColor = "#45A842";
                        }}
                        onMouseLeave={(e) => {
                            e.target.style.backgroundColor = "#58C955";
                        }}
                        onClick={async () => {
                            if (changeAnything) {
                                setChangeAnything(false);

                                if (title === "" || worth === "") {
                                    setWarningBoxMessage(
                                        "Please fill out all fields",
                                    );
                                    setWarningBox(true);
                                    return;
                                }

                                if (
                                    externalLink !== "" &&
                                    !validateUrl(externalLink)
                                ) {
                                    setWarningBoxMessage(
                                        "Please enter a valid URL",
                                    );
                                    setWarningBox(true);
                                    return;
                                }

                                // Update task in database
                                const res = await fetch(
                                    API_URL + "api/task/" + task.id + "/update",
                                    {
                                        method: "PATCH",
                                        headers: {
                                            "Content-Type": "application/json",
                                            Authorization:
                                                "Bearer " +
                                                JSON.parse(
                                                    localStorage.getItem(
                                                        "authTokens",
                                                    ),
                                                ).access,
                                        },
                                        body: JSON.stringify({
                                            title: title,
                                            due_date:
                                                dueDate == "Not Set Yet"
                                                    ? null
                                                    : dueDate,
                                            worth: worth,
                                            time_needed: timeNeeded,
                                            external_link: externalLink,
                                        }),
                                    },
                                );
                                const result = await res.json();
                                if (res.status === 200) {
                                    updateTasks().then(getTodoList());
                                    showCustomNotification({
                                        title: "Task Updated",
                                        message: "Task updated successfully",
                                        color: "green",
                                    });
                                } else {
                                    console.error(result.message, result.error);
                                    window.alert("Task update failed");
                                }
                            }
                        }}
                    >
                        {" "}
                        Save{" "}
                    </button>
                    <button
                        className="task-info-button"
                        style={{ backgroundColor: "#ff5757" }}
                        onMouseEnter={(e) => {
                            e.target.style.backgroundColor = "#CF0000";
                        }}
                        onMouseLeave={(e) => {
                            e.target.style.backgroundColor = "#ff5757";
                        }}
                        onClick={() => {
                            const response = window.confirm(
                                "Are you sure you want to delete this task?",
                            );
                            if (response) {
                                // Delete task from database
                                fetch(
                                    API_URL + "api/task/" + task.id + "/delete",
                                    {
                                        method: "DELETE",
                                        headers: {
                                            "Content-Type": "application/json",
                                            Authorization:
                                                "Bearer " +
                                                JSON.parse(
                                                    localStorage.getItem(
                                                        "authTokens",
                                                    ),
                                                ).access,
                                        },
                                    },
                                ).then((res) => {
                                    if (res.status === 200) {
                                        updateTasks().then(getTodoList());
                                    } else {
                                        showCustomNotification({
                                            title: "Task Deletion Failed",
                                            message:
                                                "Failed to delete the task",
                                            color: "red",
                                        });
                                        console.error(
                                            "Task deletion failed",
                                            res.status,
                                            res.error,
                                        );
                                    }
                                });
                            }
                        }}
                    >
                        {" "}
                        Delete{" "}
                    </button>
                </div>
            )}
            {userUpdatedTime ? (
                <div className="reset-to-algo-wrapper">
                    <div className="reset-to-algo">
                        <button
                            onClick={async () => {
                                const response = window.confirm(
                                    "Are you sure you want to reset this task to the Syllabyte algorithm suggestion?",
                                );
                                if (response) {
                                    const res = await fetch(
                                        API_URL + "api/task/resetToAlgorithm",
                                        {
                                            method: "PATCH",
                                            headers: {
                                                "Content-Type":
                                                    "application/json",
                                                Authorization:
                                                    "Bearer " +
                                                    JSON.parse(
                                                        localStorage.getItem(
                                                            "authTokens",
                                                        ),
                                                    ).access,
                                            },
                                            body: JSON.stringify({
                                                task_id: task.id,
                                            }),
                                        },
                                    );
                                    const result = await res.json();
                                    if (result.status === 200) {
                                        updateTasks();
                                    } else {
                                        setWarningBoxMessage(
                                            "Task reset failed!",
                                        );
                                        setWarningBox(true);
                                    }
                                }
                            }}
                        >
                            {" "}
                            Reset to Algorithm{" "}
                        </button>
                    </div>
                </div>
            ) : warningBox ? (
                <WarningBox message={warningBoxMessage} />
            ) : null}
            {addNewTask ? null : (
                <div className="task-info-input">
                    <label htmlFor="confidence">Confidence</label>
                    <ConfidenceSlider
                        name=""
                        type={"task"}
                        course_id={null}
                        task_id={task.id}
                        confidence={confidence}
                    />
                </div>
            )}
        </div>
    );
};

export default TaskInfo;
