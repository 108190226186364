import Shepherd from "shepherd.js";
import "shepherd.js/dist/css/shepherd.css";
import { useRef, useEffect } from "react";
import "../styles/Tour.css";
import drag from "../images/drag.gif";
import todo_tour from "../images/todo_tour.gif";
import addcourse_tour from "../images/addcourse_tour.gif";
import userService from "../api/userService";

const Tour = ({ runIntro, setRunIntro }) => {
    const tourRef = useRef(null);

    useEffect(() => {
        // check if this is a mobile device
        const isMobile =
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent,
            );

        if (isMobile) {
            setRunIntro(false);
            return;
        }

        if (runIntro && !tourRef.current) {
            const tour = new Shepherd.Tour({
                useModalOverlay: true,
                defaultStepOptions: {
                    classes: "shepherd-theme-custom",
                    scrollTo: { behavior: "smooth", block: "center" },
                },
            });

            const steps = [
                {
                    id: "welcome",
                    text: "Hey, welcome to your Syllabyte 👋",
                    buttons: [
                        {
                            text: "Start",
                            action: tour.next,
                            classes: "shepherd-button-primary",
                        },
                        {
                            text: "Skip Tour",
                            action: tour.cancel,
                            classes: "shepherd-button-secondary",
                        },
                    ],
                },
                {
                    id: "sidebar",
                    text:
                        "You can add new courses, edit your preferences and update your courses through the sidebar. " +
                        "Upload your syllabi to use our syllabus parser to speed up your setup time 💨" +
                        `<img src="${addcourse_tour}" alt='addcourse_tour' class='tour-gif' />`,
                    attachTo: {
                        element: ".side-bar",
                        on: "right",
                    },
                    buttons: [
                        {
                            text: "Back",
                            action: tour.back,
                            classes: "shepherd-button-secondary",
                        },
                        {
                            text: "Next",
                            action: tour.next,
                            classes: "shepherd-button-primary",
                        },
                    ],
                },
                {
                    id: "sidebar",
                    text:
                        "Once you add your courses, you can prioritize them as you like." +
                        `<img src="${drag}" alt='drag' class='tour-gif' />`,
                    attachTo: {
                        element: ".course-button",
                        on: "right",
                    },
                    buttons: [
                        {
                            text: "Back",
                            action: tour.back,
                            classes: "shepherd-button-secondary",
                        },
                        {
                            text: "Next",
                            action: tour.next,
                            classes: "shepherd-button-primary",
                        },
                    ],
                },
                {
                    id: "main-content",
                    text:
                        "You'll see your todo's for the day here, prioritized into buckets of importance!" +
                        `<img src="${todo_tour}" alt='drag' class='tour-gif' />`,
                    attachTo: {
                        element: ".list-pane",
                    },
                    buttons: [
                        {
                            text: "Back",
                            action: tour.back,
                            classes: "shepherd-button-secondary",
                        },
                        {
                            text: "Next",
                            action: tour.next,
                            classes: "shepherd-button-primary",
                        },
                    ],
                },
                {
                    id: "main-content",
                    text: "That's all you need 🙂, happy studying!",
                    buttons: [
                        {
                            text: "Back",
                            action: tour.back,
                            classes: "shepherd-button-secondary",
                        },
                        {
                            text: "Finish",
                            action: tour.complete,
                            classes: "shepherd-button-primary",
                        },
                    ],
                },
            ];

            steps.forEach((step) => tour.addStep(step));

            const setFirstUseDateInDB = async () => {
                const resp = await userService.updateUser({
                    first_use_date: new Date(),
                });

                if (resp.status !== 200) {
                    console.error(
                        "Error updating first use date in DB",
                        resp.error,
                    );
                }

                setRunIntro(false);
            };

            tour.on("complete", async () => {
                tourRef.current = null;
                await setFirstUseDateInDB();
            });

            tour.on("cancel", async () => {
                tourRef.current = null;
                await setFirstUseDateInDB();
            });

            tourRef.current = tour;
            tour.start();
        }

        return () => {
            if (tourRef.current) {
                tourRef.current.cancel();
                tourRef.current = null;
            }
        };
    }, [runIntro]);

    return null; // This component doesn't render anything
};

export default Tour;
