import React from "react";
import info from "../../images/landingPage/info.png";
import "../../styles/landingPage/Why.css";
import "../../styles/landingPage/Header.css";
import simpleflow from "../../images/landingPage/simpleflow.png";
import before from "../../images/landingPage/before.png";
import stathaven from "../../images/landingPage/stathaven.png";
import weekInBytes from "../../images/landingPage/weekInBytes.png";
import { useFeatureFlags } from "../../contexts/featureFlagContext";
import { useNavigate } from "react-router-dom";

const Why = () => {
    const navigate = useNavigate();
    const featureFlags = useFeatureFlags();
    return (
        <div className="why">
            <img src={info} alt="info" className="why-image" />

            <div className="why-item" style={{ backgroundColor: "#1f1f1f" }}>
                <div className="simple-flow-title title-1">
                    <h1>Effortless Setup</h1>
                    <h2
                        style={{
                            // gradient
                            background:
                                "linear-gradient(90deg, #d281ff 0%, #65aaff 100%)",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                        }}
                    >
                        Simple Steps, Big Results
                    </h2>
                </div>
                <div className="simple-flow-img">
                    <img src={simpleflow} alt="simpleflow" />
                </div>
            </div>

            <div className="why-item" style={{ backgroundColor: "black" }}>
                <div className="simple-flow-img" style={{ width: "50%" }}>
                    <img src={before} alt="simpleflow" />
                </div>
                <div className="simple-flow-title title-2">
                    <h1>Automatically Prioritized Tasks</h1>
                    <h2
                        style={{
                            // gradient
                            background:
                                "linear-gradient(90deg, #ff6565 0%, #ebdc46 100%)",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                        }}
                    >
                        Everyday
                    </h2>
                </div>
            </div>

            <div className="why-item" style={{ backgroundColor: "#1f1f1f" }}>
                <div className="simple-flow-title title-1">
                    <h1>Plan Your Semester Like Never Before</h1>
                    <h2
                        style={{
                            // gradient
                            background:
                                "linear-gradient(90deg, #65aaff 0%, #ff6565 100%)",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                        }}
                    >
                        Stathaven
                    </h2>
                </div>
                <div className="simple-flow-img" style={{ width: "70%" }}>
                    <img src={stathaven} alt="simpleflow" />
                </div>
            </div>

            <div className="why-item" style={{ backgroundColor: "black" }}>
                <div className="simple-flow-img" style={{ width: "50%" }}>
                    <img src={weekInBytes} alt="simpleflow" />
                </div>
                <div className="simple-flow-title title-2">
                    <h1>Be Prepared For Your Week</h1>
                    <h2
                        style={{
                            // gradient
                            background:
                                "linear-gradient(90deg, #ff6565 0%, #ebdc46 100%)",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                        }}
                    >
                        Weekly Reminders
                    </h2>
                </div>
            </div>

            <div className="header-buttons why-item">
                {featureFlags.enableSignupAndLogin ? (
                    <>
                        <button
                            onClick={() => {
                                navigate("/signup");
                            }}
                            id="try-today-btn"
                        >
                            Create Your Account
                        </button>
                        <button
                            onClick={() => {
                                navigate("/irl");
                            }}
                            id="try-today-btn"
                        >
                            Try our Demo!
                        </button>
                    </>
                ) : (
                    <button
                        onClick={() => {
                            window.open(
                                "https://forms.fillout.com/t/sxEYar2xp5us",
                                "_blank",
                            );
                        }}
                        id="try-today-btn"
                    >
                        Join Our Waitlist
                    </button>
                )}
            </div>
        </div>
    );
};

export default Why;
