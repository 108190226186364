import axios from "axios";
import { API_URL } from "../constants";

const axiosInstance = axios.create({
    baseURL: API_URL,
    timeout: 60000, // 60 seconds (syllabus parsing can take a while)
    headers: {
        "Content-Type": "application/json",
    },
});

// Request interceptor for API calls
axiosInstance.interceptors.request.use(
    (config) => {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        if (authTokens) {
            config.headers["Authorization"] = `Bearer ${authTokens.access}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

// Response interceptor for API calls
axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        // If the error status is 401 and there is no originalRequest._retry flag,
        // it means the token has expired and we need to refresh it
        console.log(error);
        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                const authTokens = JSON.parse(
                    localStorage.getItem("authTokens"),
                );
                const response = await axios.post(
                    `${API_URL}api/token/refresh/`,
                    {
                        refresh: authTokens.refresh,
                    },
                );

                const { access } = response.data;

                localStorage.setItem(
                    "authTokens",
                    JSON.stringify({ ...authTokens, access }),
                );

                axios.defaults.headers.common["Authorization"] =
                    `Bearer ${access}`;
                originalRequest.headers["Authorization"] = `Bearer ${access}`;

                return axiosInstance(originalRequest);
            } catch (refreshError) {
                // If refresh token fails, logout the user and redirect to login page
                localStorage.removeItem("authTokens");
                window.location.href = "/login";
                return Promise.reject(refreshError);
            }
        }

        return Promise.reject(error);
    },
);

export default axiosInstance;
