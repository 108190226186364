import React, { useState } from "react";
import "../styles/Demo.css";
import irl from "../images/irl.png";
import buildspace from "../images/buildspace.png";
import { API_URL } from "../constants";

const Demo = () => {
    // State to store authentication tokens
    const [authTokens, setAuthTokens] = useState(() => {
        return JSON.parse(localStorage.getItem("authTokens")) || null;
    });

    const handleDemoButtonClick = async () => {
        if (authTokens) {
            // Redirect to home if tokens are present
            window.location.href = "/home";
            return;
        }

        try {
            // Step 1: Create the demo user
            const createUserResponse = await fetch(
                API_URL + "api/create-demo-user",
                {
                    method: "POST", // Changed to POST as you are sending data
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        university_name: "Buildspace University",
                    }),
                },
            );

            if (createUserResponse.ok) {
                const userData = await createUserResponse.json();
                // console.log("Demo user created successfully:", userData);

                // Step 2: Authenticate and get tokens
                const authResponse = await fetch(API_URL + "api/token", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    body: new URLSearchParams({
                        username: userData.email,
                        password: "abcd",
                    }).toString(),
                });

                if (authResponse.ok) {
                    const authData = await authResponse.json();
                    setAuthTokens(authData);
                    localStorage.setItem(
                        "authTokens",
                        JSON.stringify(authData),
                    );

                    // Step 3: Add courses for the created user
                    const addCoursesResponse = await fetch(
                        API_URL + "api/add-demo-courses",
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${authData.access}`, // Correctly use token
                            },
                        },
                    );

                    if (addCoursesResponse.ok) {
                        const addCoursesData = await addCoursesResponse.json();
                        // console.log(
                        //     "Courses added successfully:",
                        //     addCoursesData,
                        // );
                        // Redirect to home after adding courses
                        window.location.href = "/home";
                    } else {
                        console.error(
                            "Failed to add courses:",
                            addCoursesResponse.statusText,
                        );
                    }
                } else {
                    console.error(
                        "Error getting auth tokens:",
                        authResponse.statusText,
                    );
                }
            } else {
                console.error(
                    "Failed to create demo user:",
                    createUserResponse.statusText,
                );
            }
        } catch (error) {
            console.error("Error occurred:", error);
        }
    };

    return (
        <div className="demo-container">
            <div className="demo-image">
                <img src={irl} alt="Demo" />
            </div>
            <div className="demo-content">
                <div className="demo-header">
                    <h1>Syllabyte</h1>
                    <img height="100" src={buildspace} alt="Buildspace" />
                </div>
                <div className="demo-content-button">
                    <button onClick={handleDemoButtonClick}>
                        Checkout the Syllabyte Demo
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Demo;
