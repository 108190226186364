import React from "react";
import { API_URL } from "../../constants";
import "../../styles/Settings.css";

const Themes = () => {
    const swapTheme = (themeName) => {
        document.documentElement.className = themeName;

        // Update db on theme update
        const updateTheme = async () => {
            const request = await fetch(API_URL + "api/theme/update", {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    Authorization:
                        "Bearer " +
                        JSON.parse(localStorage.getItem("authTokens")).access,
                },
                body: JSON.stringify({
                    theme: themeName,
                }),
            });
            const response = await request;

            if (response.status !== 200) {
                console.error(response.error);
                return;
            }
        };

        updateTheme();
    };

    return (
        <div className="themes">
            <div>Themes</div>
            <div className="theme-choices">
                <button
                    className="theme-choice"
                    id="light"
                    onClick={() => swapTheme("light")}
                >
                    light
                </button>
                <button
                    className="theme-choice"
                    id="dark"
                    onClick={() => swapTheme("dark")}
                >
                    dark
                </button>
            </div>
        </div>
    );
};

export default Themes;
