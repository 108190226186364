// components/CourseForm.js
import React from "react";
import { TextInput, NumberInput, Select, Group } from "@mantine/core";
import { YearPickerInput } from "@mantine/dates";
import { SemesterNameOptions } from "../../constants/courseConstants";

export default function CourseForm({ form }) {
    return (
        <>
            <TextInput
                label="Title"
                required
                description="The course title"
                placeholder="Introduction to Computer Science"
                {...form.getInputProps("title")}
            />

            <Group mt="md" align="flex-start">
                <TextInput
                    label="Department"
                    required
                    description="The alphabetic part of the course code or the department it belongs to"
                    placeholder="CS"
                    {...form.getInputProps("department")}
                />

                <NumberInput
                    label="Code"
                    required
                    description="The numeric part of the course code"
                    placeholder="101"
                    min={0}
                    {...form.getInputProps("code")}
                />
            </Group>

            <Group mt="md" align="flex-start">
                <Select
                    label="Semester"
                    required
                    description="The semester the course is being offered in"
                    placeholder="Fall"
                    {...form.getInputProps("semester")}
                    data={Array.from(SemesterNameOptions.keys())}
                />

                <YearPickerInput
                    label="Year"
                    required
                    miw={100}
                    description="The year the course is being offered in"
                    placeholder="2024"
                    minDate={new Date(2022, 0, 1)}
                    {...form.getInputProps("year")}
                />
            </Group>
        </>
    );
}
