import React from "react";
import "../styles/SeeList.css";
import { useNavigate } from "react-router-dom";
const SeeList = () => {
    const navigate = useNavigate();
    return (
        <div
            className="todays-syllabyte"
            onClick={() => {
                navigate("/home");
            }}
        >
            <div className="todays-syllabyte-text">Syllabyte</div>
        </div>
    );
};

export default SeeList;
