import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { useLoaderData } from "react-router-dom";
import moment from "moment";
import "moment/locale/en-gb";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../styles/CalendarView.scss";
import SeeList from "./seeListButton";
// Setup the localizer for react-big-calendar
const localizer = momentLocalizer(moment);

const CalendarView = () => {
    const { tasks } = useLoaderData();
    const [events, setEvents] = useState([]);

    // convert todo items to events
    useEffect(() => {
        const newEvents = [];

        for (const todoItem of tasks) {
            newEvents.push({
                title: todoItem.title,
                start: new Date(todoItem.starts_at + " 00:00:00"),
                end: new Date(todoItem.due_date + " 23:59:59"),
            });
        }

        setEvents(newEvents);
    }, [tasks]);

    return (
        <>
            <SeeList />
            <div className="calendar">
                <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: "40rem" }}
                    views={["month"]}
                    popup
                />
            </div>
        </>
    );
};

export default CalendarView;
