import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/LoginPage.css";
import Submit from "../images/Submit.png";
import "../fonts/static/Outfit-Light.ttf";
import "../styles/fonts.css";
import { API_URL } from "../constants";

const LoginPage = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate();

    const loginUser = async (username, password) => {
        const formData = new FormData();
        formData.append("username", username);
        formData.append("password", password);

        const response = await fetch(API_URL + "api/token", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: new URLSearchParams(formData),
        });
        const data = await response.json();

        if (response.status === 200) {
            localStorage.setItem("authTokens", JSON.stringify(data));
            return true;
        } else {
            // We should check if the user has to verify their email
            const isverified_response = await fetch(
                API_URL + "api/isverified?email=" + username,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            );

            const isVerified = await isverified_response.json();

            if (
                isverified_response.status == 200 &&
                isVerified.verified == false
            ) {
                // ask user if they want to resend the email through a dialog
                const resend = window.confirm(
                    "Your email has not been verified. Would you like to resend the email verification?",
                );

                if (resend) {
                    const sendemail_response = await fetch(
                        API_URL + "api/send-verification-email",
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({ email: username }),
                        },
                    );

                    await sendemail_response.json();

                    if (sendemail_response.status == 200) {
                        alert("Email sent");
                    } else {
                        alert("Error sending email");
                    }

                    return false;
                }
            } else if (isVerified.status == 400) {
                alert("User not found");
            } else {
                alert("Incorrect username or password");
            }
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        loginUser(username, password).then((response) => {
            if (response) {
                navigate("/home");
            }
        });
    };

    useEffect(() => {
        if (localStorage.getItem("authTokens")) navigate("");
    }, []);

    return (
        <div className="login-page">
            <h1>Syllabyte</h1>
            <form onSubmit={handleSubmit}>
                <div className="login-inputs">
                    <input
                        className="username"
                        type="text"
                        name="username"
                        placeholder="Email"
                        value={username}
                        onChange={(event) => setUsername(event.target.value)}
                    />
                    <input
                        className="password"
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                    />
                </div>
                <div className="login-submit">
                    <input
                        type="image"
                        alt="login-button"
                        src={Submit}
                        value="Submit"
                    />
                </div>
            </form>
            {/* Temporarily disable signup */}
            {/* <div className="login-signup-link">
                <p>
                    Don&apos;t have an account?
                    <button type="button" onClick={() => navigate("/signup")}>
                        Sign Up
                    </button>
                </p>
            </div> */}
        </div>
    );
};
export default LoginPage;
