import axiosInstance from "./axiosConfig";
import { showCustomNotification } from "../utils/notification.js";

const taskService = {
    getAllUserTasks: async () => {
        try {
            return await axiosInstance.get(`api/user/tasks`);
        } catch (error) {
            showCustomNotification({
                title: "Error",
                message: "Task fetching failed!",
                color: "red",
            });
            throw error;
        }
    },

    getAllUserTasksForCourse: async (courseId) => {
        try {
            return await axiosInstance.get(`api/tasks/course/${courseId}`);
        } catch (error) {
            showCustomNotification({
                title: "Error",
                message: "Task fetching failed!",
                color: "red",
            });
            throw error;
        }
    },

    getAllTasksForCourseDB: async (courseDBId) => {
        try {
            return await axiosInstance.get(`api/tasks/courseDB/${courseDBId}`);
        } catch (error) {
            showCustomNotification({
                title: "Error",
                message: "Task fetching failed!",
                color: "red",
            });
            throw error;
        }
    },

    addNewTask: async (taskData) => {
        try {
            return await axiosInstance.post("api/task/create", taskData);
        } catch (error) {
            showCustomNotification({
                title: "Error",
                message: "Task creation failed!",
                color: "red",
            });
            throw error;
        }
    },

    updateTaskGrade: async (taskId, grade) => {
        try {
            return await axiosInstance.patch(`api/task/${taskId}/grade/`, {
                grade,
            });
        } catch (error) {
            throw error;
        }
    },
};

export default taskService;
