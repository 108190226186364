import React, { createContext, useContext, useState, useEffect } from "react";
import { API_URL } from "../constants";

const FeatureFlagContext = createContext();

const useFeatureFlags = () => {
    return useContext(FeatureFlagContext);
};

const FeatureFlagProvider = ({ children }) => {
    const [featureFlags, setFeatureFlags] = useState({
        createCourse: true,
        parseSyllabus: true,
        showTodoWorthInMainList: false,
        enableDatePicker: true,
        enableSignupAndLogin: true,
        gradeCalculator: true,
        // Add other feature flags here
    });

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("authTokens"));
        const requestHeaders = {
            "Content-Type": "application/json",
        };
        // Only add the Authorization header if the user is logged in.
        if (token) {
            requestHeaders["Authorization"] = "Bearer " + token.access;
        }
        fetch(API_URL + "api/feature-flags", {
            method: "GET",
            headers: requestHeaders,
        })
            .then((response) => response.json())
            .then((data) => setFeatureFlags(data))
            .catch((error) =>
                console.error("Error fetching feature flags:", error),
            );
    }, []);

    return (
        <FeatureFlagContext.Provider value={featureFlags}>
            {children}
        </FeatureFlagContext.Provider>
    );
};

export { FeatureFlagContext, FeatureFlagProvider, useFeatureFlags };
