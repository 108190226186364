import React from "react";
import { useNavigate } from "react-router-dom";
import SyllabyteLogo from "../../images/SyllabyteLogo.png";
import "../../styles/landingPage/Header.css";
import { useFeatureFlags } from "../../contexts/featureFlagContext";
const Header = () => {
    const navigate = useNavigate();
    const featureFlags = useFeatureFlags();

    return (
        <div className="header">
            <div className="logo">
                <img src={SyllabyteLogo} alt="logo" />
            </div>
            <div className="header-text">Syllabyte</div>
            <div className="header-buttons">
                {featureFlags.enableSignupAndLogin ? (
                    <>
                        <button
                            type="button"
                            onClick={() => {
                                navigate("/login");
                            }}
                        >
                            Login
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                window.open("/signup", "_blank");
                            }}
                        >
                            Sign Up
                        </button>
                    </>
                ) : (
                    <button
                        type="button"
                        onClick={() => {
                            window.open(
                                "https://forms.fillout.com/t/sxEYar2xp5us",
                                "_blank",
                            );
                        }}
                    >
                        Waitlist
                    </button>
                )}
            </div>
        </div>
    );
};

export default Header;
