import React from "react";
import { SEMESTERS } from "../constants/courseConstants";
import { useLoaderData } from "react-router-dom";
import HeatMap from "../components/heatmap";
import "../styles/PublicCoursePage.css";
import SyllabyteLogo from "../images/SyllabyteLogo.png";
import { API_URL } from "../constants.js";
import GradeCalculator from "../components/coursePage/gradeCalculator.js";
import TaskTable from "../components/addCoursePage/taskTable.js";
import { useFeatureFlags } from "../contexts/featureFlagContext.js";
const PublicCoursePage = () => {
    const course_info = useLoaderData();
    const tasks = course_info.tasks;
    const featureFlags = useFeatureFlags();

    return (
        <div className="public-course-page">
            <div className="public-course-page-sidebar">
                <div className="public-course-page-sidebar-text">
                    <div className="public-course-page-sidebar-logo">
                        <a
                            onClick={() => {
                                window.open(`${API_URL}`);
                            }}
                        >
                            <img src={SyllabyteLogo} alt="Syllabyte Logo" />
                        </a>
                    </div>
                    <p>Want to see your semester with this course?</p>
                    <button
                        onClick={() => {
                            window.open(`${API_URL}signup`);
                        }}
                    >
                        Sign up
                    </button>
                </div>
            </div>
            <div className="public-course-page-main">
                <div className="public-course-page-main-header">
                    <div className="public-course-page-main-header-title">
                        <h1>{course_info.dept + " " + course_info.code}</h1>
                        <h2>{course_info.title}</h2>
                    </div>
                    <div
                        className="public-course-page-main-header-semester"
                        style={{
                            color: `${SEMESTERS.get(course_info.semester).color}`,
                        }}
                    >
                        <h2>
                            {SEMESTERS.get(course_info.semester).name},{" "}
                            {course_info.year}
                        </h2>
                    </div>
                </div>
                <div className="public-course-page-main-heatmap">
                    <HeatMap
                        tasks={tasks}
                        semester_info={{
                            year: course_info.year,
                            semester: course_info.semester,
                        }}
                        chartType="weightedTasks"
                    />
                </div>
                {featureFlags.gradeCalculator ? (
                    <GradeCalculator tasks={tasks} isPublic={true} />
                ) : (
                    <>
                        <TaskTable
                            tasks={tasks}
                            tableType="assignment"
                            showGrades={false}
                        />
                        <TaskTable
                            tasks={tasks}
                            tableType="exam"
                            showGrades={false}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default PublicCoursePage;
