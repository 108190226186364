import React from "react";
import warning from "../../images/warning.png";
import "../../styles/general/WarningBox.css";

const WarningBox = ({ message }) => {
    return (
        <div className="warning-box">
            <img src={warning} alt="warning" />
            <p>{message}</p>
        </div>
    );
};

export default WarningBox;
