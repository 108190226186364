import React from "react";
import PersonalInfo from "../components/register/personalInfo";
import "../styles/RegisterPage.css";
import SyllabyteLogo from "../images/SyllabyteLogo.png";

const RegisterPage = () => {
    return (
        <div className="register-page">
            <div className="register-page-logo">
                <img src={SyllabyteLogo} alt="Syllabyte Logo" />
            </div>
            <div className="register-page-signup">
                <PersonalInfo />
            </div>
        </div>
    );
};

export default RegisterPage;
