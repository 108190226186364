import React from "react";
import "../../styles/ToDo.css";
import SeeList from "../seeListButton";
import TaskList from "./tasklist";
import DatePicker from "./datePicker";
import { useFeatureFlags } from "../../contexts/featureFlagContext";

function getDate() {
    const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    const dayNames = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
    ];

    const newDate = new Date();
    const day = newDate.getDay();
    const date = newDate.getDate();
    const month = newDate.getMonth();
    const year = newDate.getFullYear();

    return `${dayNames[day]} ${date} ${monthNames[month]}, ${year}`;
}
const SyllabyteList = () => {
    const featureFlags = useFeatureFlags();

    return (
        <div className="list-wrapper">
            <SeeList />
            <div className="date">
                {featureFlags.enableDatePicker ? (
                    <DatePicker />
                ) : (
                    <p>{getDate()}</p>
                )}
            </div>
            <TaskList />
        </div>
    );
};

export default SyllabyteList;
