import React from "react";
import { modals } from "@mantine/modals";
import { Text } from "@mantine/core";

// style the modal
const modalStyles = {
    title: {
        fontSize: "24px",
        fontWeight: "bold",
    },
};

export const alertModal = (
    title,
    text,
    onConfirm = () => {},
    onCancel = () => {},
    confirmString = "Confirm",
    cancelString = "Cancel",
) => {
    try {
        modals.openConfirmModal({
            title,
            children: (
                <Text size="sm" c="black">
                    {text}
                </Text>
            ),
            labels: { confirm: confirmString, cancel: cancelString },
            onConfirm,
            onCancel,
            styles: modalStyles,
        });
    } catch (error) {
        console.error("Error opening modal:", error);
    }
};
