import React, { useState, useEffect, useCallback, useContext } from "react";
import { TextInput, Select, Button, NumberInput } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import taskService from "../../api/taskService";
import courseService from "../../api/courseService";
import { showCustomNotification } from "../../utils/notification";
import { ChangeContext } from "../../contexts/changeContext";
import { LoadingOverlay } from "@mantine/core";
import { validateUrl } from "../../utils/generalUtils";

const CreateTaskForm = ({ onClose }) => {
    const [formData, setFormData] = useState({
        title: "",
        link: "",
        dueDate: null,
        course: "",
        worth: null,
        type: "Assignment",
    });
    const [courses, setCourses] = useState([]);
    const fetchTasks = useContext(ChangeContext);
    const [isLoading, setIsLoading] = useState(false);

    const fetchCourses = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await courseService.getAllCoursesForUser();
            const userCourses = response.data;
            setCourses(
                userCourses.map((course) => ({
                    value: `${course.id}:${course.course_info_full.dept} ${course.course_info_full.code}`,
                    label: `${course.course_info_full.dept} ${course.course_info_full.code} 
                        - ${course.course_info_full.title}`,
                    id: course.id,
                })),
            );
        } catch (error) {
            showCustomNotification({
                title: "Fetch Courses Failed",
                message: "try refreshing the page",
                color: "red",
            });
            console.error("Error fetching courses:", error);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchCourses();
    }, [fetchCourses]);

    const handleInputChange = (name, value) => {
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const courseId = formData.course.split(":")[0];
            const newTask = {
                title: formData.title,
                link: formData.link,
                due_date: formData.dueDate,
                course_id: courseId,
                worth: formData.worth,
                categories: formData.type.toLowerCase(),
            };
            const response = await taskService.addNewTask(newTask);

            if (response.status === 200) {
                setFormData({
                    title: "",
                    link: "",
                    dueDate: null,
                    course: null,
                    worth: null,
                    type: "Assignment",
                });

                // reload the tasks
                await fetchTasks();
                onClose();

                showCustomNotification({
                    title: "Task Created",
                    message: "Task created successfully!",
                    color: "green",
                });
            } else {
                showCustomNotification({
                    title: "Task Creation Failed",
                    message: "Failed to create task",
                    color: "red",
                });
            }
        } catch (error) {
            console.error("Error creating task:", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <LoadingOverlay visible={isLoading} />
            <TextInput
                label="Title"
                value={formData.title}
                onChange={(e) => handleInputChange("title", e.target.value)}
                required
            />
            <Select
                label="Affiliated Course"
                data={courses}
                value={formData.course}
                onChange={(value) => handleInputChange("course", value)}
                required
            />
            <DatePickerInput
                label="Due Date"
                value={formData.dueDate}
                onChange={(value) => handleInputChange("dueDate", value)}
                placeholder="Pick a date"
                required
            />
            <NumberInput
                label="Weight"
                value={formData.worth}
                onChange={(value) => handleInputChange("worth", value)}
                required
                min={0}
                max={100}
                placeholder="How much is this task worth?"
            />
            <br />
            <Select
                label="Task Type"
                data={["Assignment", "Exam"]}
                value={formData.type}
                onChange={(value) => handleInputChange("type", value)}
                defaultValue="Assignment"
            />
            <TextInput
                label="External Link"
                value={formData.link}
                onChange={(e) => handleInputChange("link", e.target.value)}
            />
            <Button
                type="submit"
                mt="md"
                color="green"
                style={{ display: "block", margin: "20px auto 5px auto" }}
                onClick={handleSubmit}
                disabled={
                    !formData.title ||
                    !formData.course ||
                    !formData.dueDate ||
                    !formData.worth ||
                    !formData.type ||
                    (formData.link != "" && !validateUrl(formData.link))
                }
            >
                Create Task
            </Button>
        </form>
    );
};

export default CreateTaskForm;
