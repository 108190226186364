import React, { useEffect, useState } from "react";
import "../styles/App.css";
import "../styles/CourseSelection.css";
import "../components/addCoursePage/selectOrDrop.js";
import CourseSelectionButton from "../components/addCoursePage/selectOrDrop.js";
import SeeList from "../components/seeListButton";
import CoursePreviewModal from "../components/addCoursePage/coursePreviewModal";
import { API_URL } from "../constants";
import CreateCourseButton from "../components/createCourse/createCourseButton";
import { useFeatureFlags } from "../contexts/featureFlagContext";
import { IconLock, IconUsers } from "@tabler/icons-react";
import SyllabusUploader from "../components/createCourse/SyllabusUploader.js";
import { useDisclosure } from "@mantine/hooks";
import { Group } from "@mantine/core";

const CourseSelection = () => {
    const [university, setUniversity] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const featureFlags = useFeatureFlags();
    const [opened, { open, close, toggle }] = useDisclosure(false);
    const [syllabusFile, setSyllabusFile] = useState(null);

    let totalCourses = 0;

    const semesters = ["Fall", "Winter", "Summer"];

    useEffect(() => {
        const getUniversity = async () => {
            const response = await fetch(API_URL + "api/university", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization:
                        "Bearer " +
                        JSON.parse(localStorage.getItem("authTokens")).access,
                },
            });
            const data = await response.json();
            setUniversity(data);
        };
        getUniversity();
    }, []);

    useEffect(() => {
        const getSearchResults = async () => {
            const response = await fetch(
                API_URL + "api/courses/search/" + searchTerm,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " +
                            JSON.parse(localStorage.getItem("authTokens"))
                                .access,
                    },
                },
            );

            const data = await response.json();
            setSearchResults(data);

            if (searchTerm === "") {
                totalCourses = data.length;
            }
        };
        getSearchResults();
    }, [searchTerm]);

    const handleSyllabusUpload = (file) => {
        if (file) {
            setSyllabusFile(file);
            open(); // Open the CreateCourse modal
        }
    };

    return (
        <div>
            <div>
                <SeeList />
                <p className="uniname">{university.name}</p>

                <div className="search-container">
                    <div className="search-bar">
                        <input
                            type="text"
                            placeholder="Search..."
                            onChange={(event) => {
                                setSearchTerm(event.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className="search-results-container">
                    {searchResults.map((course) => (
                        <div key={course.id} className="search-results">
                            <button
                                className="search-results-button"
                                onClick={() => setSelectedCourse(course)}
                            >
                                <div className="course-info">
                                    <span>
                                        {course.dept}
                                        {course.code} {course.title}
                                    </span>
                                </div>
                                <div className="course-extra-info">
                                    <span className="course-semester-info">
                                        {semesters[course.semester - 1]}{" "}
                                        {course.year}
                                    </span>
                                    <span className="course-enrollment-info">
                                        {!course.is_public && (
                                            <IconLock
                                                size={20}
                                                className="lock-icon"
                                            />
                                        )}
                                        <IconUsers
                                            size={20}
                                            className={`user-icon ${course.enrollment_count > 0 ? "enrolled" : ""}`}
                                        />
                                        <span className="enrollment-count">
                                            {course.enrollment_count}
                                        </span>
                                    </span>
                                </div>
                            </button>
                            <CourseSelectionButton
                                course={course}
                                num_courses={totalCourses}
                            />
                        </div>
                    ))}
                </div>
                {selectedCourse && (
                    <CoursePreviewModal
                        course={selectedCourse}
                        onClose={() => setSelectedCourse(null)}
                    />
                )}
            </div>
            {featureFlags.createCourse && (
                <Group mt="xl" justify="center" gap={60}>
                    <SyllabusUploader onUpload={handleSyllabusUpload} />
                    <CreateCourseButton
                        opened={opened}
                        toggleModal={toggle}
                        closeModal={close}
                        syllabusFile={syllabusFile}
                    />
                </Group>
            )}
        </div>
    );
};

export default CourseSelection;
