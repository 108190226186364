import Sidebar from "../components/sidebar";
import "../styles/App.css";
import "../fonts/static/Outfit-Light.ttf";
import "../styles/fonts.css";
import LandingPage from "./landingPage";
import jwt_decode from "jwt-decode";
import Tour from "../components/tour";
import Loader from "../components/loader";
import userService from "../api/userService";
import courseService from "../api/courseService";
import { formatDateOrNull, getTodaysDate } from "../utils/generalUtils";
import { Outlet, useNavigate } from "react-router-dom";
import { React, useEffect, useState } from "react";
import { CourseContext } from "../contexts/courseContext";
import { ChangeContext } from "../contexts/changeContext";
import { UserContext } from "../contexts/userContext";
import { AuthContext } from "../contexts/authContext";
import { API_URL } from "../constants";

function Root() {
    const navigate = useNavigate();
    const [courseInfo, setCourseInfo] = useState([]);
    const [coursesLoaded, setCoursesLoaded] = useState(false);

    const [user, setUser] = useState(null);
    const [todo, setTodo] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    const [authTokens, setAuthTokens] = useState(
        JSON.parse(localStorage.getItem("authTokens"))
            ? JSON.parse(localStorage.getItem("authTokens"))
            : null,
    );
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [runIntro, setRunIntro] = useState(false);

    const getCourseInfo = async () => {
        const response = await fetch(API_URL + "api/courses", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + authTokens.access,
            },
        });
        const data = await response.json();
        const courseList = [];
        for (const course of data) {
            const courseInfo = await courseService.getCourseDB(
                course.course_info,
            );

            courseList.push({
                ...course,
                course_info_full: courseInfo,
            });
        }

        setCourseInfo(courseList);
        setCoursesLoaded(true);
    };

    const getToday = () => {
        const today = new Date();
        return today;
    };

    const getTodoList = async (date = null) => {
        setIsLoaded(false);
        const tasks = [];
        if (!authTokens) {
            setIsAuthenticated(false);
            return;
        }
        if (!date) {
            date = getTodaysDate();
        }
        const response = await fetch(
            API_URL + "api/tasks/" + formatDateOrNull(date),
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + authTokens.access,
                },
            },
        );
        const returned_stat = await response.json();

        const setTasks = () => {
            for (const task of returned_stat) {
                if (task.rank >= 0) {
                    tasks.push({
                        ...task,
                    });
                }
            }
        };

        setTasks();
        setTodo(tasks);
        setIsLoaded(true);
    };

    const getUserInfo = async () => {
        const response = await userService.getUserInfo();

        if (response.status === 200) {
            setUserInfo(response.data);
        } else if (response.status === 400) {
            alert("user not found");
        } else if (response.status === 401) {
            // console.log('unauthorized');
            localStorage.removeItem("authTokens");
            setAuthTokens(null);
            setIsAuthenticated(false);
        }
    };

    const initUser = async (date) => {
        setIsLoaded(false);
        setCoursesLoaded(false);
        const tasks = [];
        const courseList = [];

        if (!date) {
            date = getToday();
        }
        setSelectedDate(date);

        // We need to format our date to year month day
        // This is because the backend expects the date in this format
        // YYYY-MM-DD
        const formatedDate = getTodaysDate();

        const response = await fetch(API_URL + "api/init/" + formatedDate, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + authTokens.access,
            },
        });

        const userdata = await response.json();

        const setTasks = () => {
            for (const task of userdata.tasks) {
                if (task.rank >= 0) {
                    tasks.push({
                        ...task,
                    });
                }
            }

            setTodo(tasks);
        };

        const setCourses = () => {
            courseList.push(...userdata.courses);
            setCoursesLoaded(true);
            setCourseInfo(courseList);
        };

        const setTheme = () => {
            if (userdata.user.theme) {
                document.documentElement.className = userdata.user.theme;
            } else {
                // fallback to the dark theme
                document.documentElement.className = "dark";
            }
        };

        if (response.status == 200) {
            setUserInfo(userdata.user);
            setTasks();
            setCourses();
            setTheme();
            setRunIntro(userdata["is_first_use"]);
            setIsLoaded(true);
            navigate("/home");
        } else if (response.status == 400) {
            alert("user not found");
        } else if (response.status == 401) {
            localStorage.removeItem("authTokens");
            setAuthTokens(null);
            setIsAuthenticated(false);
        }
    };

    const handleAuthDataChange = (state) => {
        setIsAuthenticated(state);
    };

    const handleOutletDataChange = () => {
        getCourseInfo();
    };

    const handleUserInfoChange = async () => {
        getUserInfo();
    };

    const contextData = {
        authFunc: handleAuthDataChange,
        user_id: user ? user.user_id : null,
    };

    useEffect(() => {
        const fetchData = async () => {
            await initUser(selectedDate);
        };

        if (user) fetchData();
    }, [user]);

    useEffect(() => {
        const lsAuth = localStorage.getItem("authTokens");

        if (lsAuth) {
            const authTokens = JSON.parse(lsAuth);
            if (!isAuthenticated && !user) {
                setAuthTokens(authTokens);
                setIsAuthenticated(true);
                setUser(jwt_decode(authTokens.access));
            } else {
                // check if authTokens is expired
                const decoded = jwt_decode(authTokens.access);
                const currentTime = Date.now() / 1000;
                if (decoded.exp < currentTime) {
                    setAuthTokens(null);
                    setIsAuthenticated(false);
                    window.location.href = "/login";
                }
            }
        } else {
            setIsAuthenticated(false);
        }
    }, []);

    useEffect(() => {
        console.log("selected date", selectedDate);
        getTodoList(selectedDate);
    }, [selectedDate]);

    return (
        <div className="App">
            {isAuthenticated ? (
                <AuthContext.Provider value={contextData}>
                    <ChangeContext.Provider value={getTodoList}>
                        <div
                            className="side-bar"
                            style={{
                                display:
                                    user && isAuthenticated ? "block" : "none",
                            }}
                        >
                            <Sidebar
                                courses={courseInfo}
                                user={userInfo}
                                coursesLoaded={coursesLoaded}
                            />
                        </div>
                        <div className="list-pane">
                            <UserContext.Provider value={handleUserInfoChange}>
                                <CourseContext.Provider
                                    value={handleOutletDataChange}
                                >
                                    {isLoaded ? (
                                        <Outlet
                                            context={[
                                                [todo, setTodo],
                                                [userInfo, setUserInfo],
                                                [selectedDate, setSelectedDate],
                                            ]}
                                        />
                                    ) : (
                                        <Loader />
                                    )}
                                </CourseContext.Provider>
                            </UserContext.Provider>
                        </div>
                        <Tour runIntro={runIntro} setRunIntro={setRunIntro} />
                    </ChangeContext.Provider>
                </AuthContext.Provider>
            ) : (
                <LandingPage />
            )}
        </div>
    );
}

export default Root;
