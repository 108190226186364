import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Preferences from "../components/settingsPage/preferences";
import "../styles/CoursePage.css";
import SeeList from "../components/seeListButton";
import TaskInfo from "../components/coursePage/taskInfo";
import taskService from "../api/taskService";
import CourseSettings from "../components/coursePage/courseSettings";
import { isEmptyObject } from "jquery";
import { Tabs } from "@mantine/core";
import GradeCalculator from "../components/coursePage/gradeCalculator";

function CoursePage() {
    const { courseId } = useParams();

    const [dueTasks, setDueTasks] = useState([]);
    const [doneTasks, setDoneTasks] = useState([]);
    const [courseInfo, setCourseInfo] = useState({});
    const [addNewTask, setAddNewTask] = useState(false);

    const [taskInfo, setTaskInfo] = useState({});

    const getTasks = async () => {
        const response = await taskService.getAllUserTasksForCourse(courseId);
        const data = response.data;

        const due = [];
        const done = [];

        for (const task of data.tasks) {
            isTaskDone(task)
                ? done.push({
                      ...task,
                  })
                : due.push({ ...task });
        }

        setDueTasks(due);
        setDoneTasks(done);
        setCourseInfo(data.courseInfo);
    };

    const isTaskDone = (task) => {
        const today = new Date(new Date().setHours(0, 0, 0, 0));
        const dueDate = task.due_date
            ? new Date(`${task.due_date}T00:00:00`)
            : null;

        // For now, put tasks with unset dates in the "due" category.
        // TODO: Change this to a "not set yet" category
        const dateNotSet = dueDate === null || dueDate.getFullYear() <= 2001;
        // TODO: fix issue where a new task that is due today shows up as past due.
        // i suspect it has something to do with the time zone when the task is created.
        const isPastDue = !dateNotSet && dueDate < today;
        if (dateNotSet) {
            task.due_date = "Not Set Yet";
        }
        if (task.status === 2 || isPastDue) {
            return true;
        } else {
            return false;
        }
    };

    useEffect(() => {
        getTasks();
        setTaskInfo({});
        setAddNewTask(false);
    }, [courseId]);

    const changeTaskInfo = (task) => {
        setTaskInfo(task);
        setAddNewTask(false);
    };

    return (
        <div className="course-page-wrapper">
            <SeeList />
            <Tabs defaultValue="tasks" data-variant="outline">
                <Tabs.List>
                    <Tabs.Tab value="tasks" className="tab">
                        Tasks
                    </Tabs.Tab>
                    <Tabs.Tab value="grade-calculator" className="tab">
                        Grade Calculator
                    </Tabs.Tab>
                    <Tabs.Tab value="settings" ml="auto" className="tab">
                        Settings
                    </Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value="tasks">
                    <div className="course-wrapper">
                        <div className="course-tasks">
                            <div className="course-wrapper-color">
                                <div className="tasks-list">
                                    <p className="course-tasks-header"> Due </p>
                                    <div className="course-tasks-table">
                                        {dueTasks
                                            .sort((a, b) => {
                                                return (
                                                    new Date(a.due_date) -
                                                    new Date(b.due_date)
                                                );
                                            })
                                            .map((task) => (
                                                <button
                                                    key={task.id}
                                                    className="task-button"
                                                    onClick={() =>
                                                        changeTaskInfo(task)
                                                    }
                                                >
                                                    <div>{task.title}</div>
                                                    <div>{task.due_date}</div>
                                                </button>
                                            ))}
                                    </div>
                                    <p className="course-tasks-header">
                                        {" "}
                                        Done{" "}
                                    </p>
                                    <div className="course-tasks-table">
                                        {doneTasks
                                            .sort((a, b) => {
                                                return (
                                                    new Date(a.due_date) -
                                                    new Date(b.due_date)
                                                );
                                            })
                                            .map((task) => (
                                                <button
                                                    key={task.id}
                                                    className="task-button"
                                                    onClick={() =>
                                                        changeTaskInfo(task)
                                                    }
                                                >
                                                    <div>{task.title}</div>
                                                    <div>{task.due_date}</div>
                                                </button>
                                            ))}
                                    </div>
                                </div>
                                <div className="add-task-button">
                                    <button
                                        onClick={() => {
                                            setAddNewTask(true);
                                        }}
                                    >
                                        Add Task
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="course-right-wrapper">
                            <div className="task-info">
                                {isEmptyObject(taskInfo) ? (
                                    !addNewTask ? (
                                        <div className="task-info-wrapper">
                                            <p> Select a task </p>
                                        </div>
                                    ) : (
                                        <TaskInfo
                                            task={taskInfo}
                                            addNewTask={addNewTask}
                                            courseId={courseId}
                                            updateTasks={getTasks}
                                        />
                                    )
                                ) : (
                                    <TaskInfo
                                        task={taskInfo}
                                        addNewTask={addNewTask}
                                        updateTasks={getTasks}
                                    />
                                )}
                            </div>
                            <div className="confidence-pref">
                                <Preferences
                                    show_explanation={false}
                                    type="course"
                                    course_id={courseId ? courseId : null}
                                    course_name={
                                        courseInfo.dept + courseInfo.code
                                    }
                                    exam_confidence={courseInfo.exam_confidence}
                                    assignment_confidence={
                                        courseInfo.assignment_confidence
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </Tabs.Panel>
                <Tabs.Panel value="grade-calculator">
                    <GradeCalculator
                        tasks={[...dueTasks, ...doneTasks]}
                        isPublic={false}
                    />
                </Tabs.Panel>
                <Tabs.Panel value="settings" style={{ height: "80%" }}>
                    <CourseSettings course={courseInfo} />
                </Tabs.Panel>
            </Tabs>
        </div>
    );
}

export default CoursePage;
