import React, { useEffect, useState } from "react";
import { Button, Text, Switch, Loader, Select } from "@mantine/core";
import "../../styles/CourseSettings.css";
import { SEMESTERS } from "../../constants/courseConstants";
import { useOutletContext } from "react-router-dom";
import { showCustomNotification } from "../../utils/notification";
import courseService from "../../api/courseService";
import { alertModal } from "../../utils/alertModal";

const CourseSettings = ({ course }) => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        title: "",
        dept: "",
        code: null,
        semester: "",
        is_public: false,
        year: "",
    });
    const [userInfo, setUserInfo] = useOutletContext()[1];
    const [courseLoaded, setCourseLoaded] = useState(false);

    const [isDisabled, setIsDisabled] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "code") {
            setFormData({ ...formData, [name]: Number(value) });
        } else if (name === "dept") {
            setFormData({ ...formData, [name]: value.slice(0, 5) });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const performUpdate = async () => {
            const resp = await courseService.updateCourseDB({
                ...formData,
                id: course.id,
            });

            if (resp.status === 200) {
                showCustomNotification({
                    title: "Course updated successfully!",
                    message:
                        "Your personal course has been updated successfully",
                    color: "green",
                });
            } else {
                showCustomNotification({
                    title: "Course update failed!",
                    message: "Failed to update your personal course",
                    color: "red",
                });
            }

            setLoading(false);
        };

        if (course.is_public !== formData.is_public) {
            const message = formData.is_public
                ? "You are about to make this course public. This action cannot be undone. " +
                  " Are you sure you want to proceed?"
                : "You are about to make this course private. " +
                  "Are you sure you want to proceed?";

            alertModal("Are you sure?", message, performUpdate, () =>
                setLoading(false),
            );
        } else if (course.is_public) {
            alertModal(
                "Are you sure?",
                "Updating course information will affect all users " +
                    "enrolled in this course.",
                performUpdate,
                () => setLoading(false),
            );
        } else {
            performUpdate();
        }

        setLoading(false);
    };

    const handleSwitchChange = (event) => {
        if (course.is_public) {
            showCustomNotification({
                title: "Action not allowed",
                message:
                    "To maintain consistency for published courses, we have temporarily disabled the option to revert" +
                    " public courses to private status.",
                color: "red",
            });
        } else {
            setFormData({
                ...formData,
                is_public: event.target.checked,
            });
        }
    };

    const renderInput = (label, name, type = "text") => (
        <div className="form-group">
            <Text mb="sm">{label}</Text>
            <input
                type={type}
                name={name}
                value={formData[name]}
                onChange={handleInputChange}
                disabled={isDisabled}
                maxLength={name === "dept" ? 5 : undefined}
            />
        </div>
    );

    useEffect(() => {
        setCourseLoaded(false);
        if (course && Object.keys(course).length > 0) {
            setFormData({
                title: course.title || "",
                dept: (course.dept || "").slice(0, 5),
                code: Number(course.code) || null,
                semester: course.semester || "",
                is_public: course.is_public || false,
                year: course.year || "",
            });
            setCourseLoaded(true);
            setIsDisabled(course.owner != userInfo.id);
        }
    }, [course]);

    const currentYear = new Date().getFullYear();
    const yearOptions = Array.from({ length: 3 }, (_, i) => currentYear + i);

    return (
        <div className="course-settings">
            {!courseLoaded ? (
                <Loader size="xl" />
            ) : (
                <>
                    <div className="course-banner">
                        <div className="course-banner-content">
                            <h1>{course.title}</h1>
                            <p>
                                {course.dept} {course.code}
                            </p>
                        </div>
                        <div className="course-banner-footer">
                            <p
                                style={{
                                    color: SEMESTERS.get(course.semester).color,
                                }}
                            >
                                {SEMESTERS.get(course.semester).name},{" "}
                                {course.year}
                            </p>
                            <p
                                style={
                                    course.is_public
                                        ? { color: "green" }
                                        : { color: "red" }
                                }
                            >
                                {course.is_public ? "Public" : "Private"}
                            </p>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="about-course-form form-inputs course-form-container">
                            {renderInput("Title", "title")}
                            {renderInput("Department", "dept")}
                            {renderInput("Course Code", "code", "number")}
                            <div className="form-group">
                                <Text mb="sm">Year</Text>
                                <Select
                                    data={yearOptions.map((year) => ({
                                        value: String(year),
                                        label: String(year),
                                    }))}
                                    value={String(formData.year)}
                                    onChange={(value) =>
                                        handleInputChange({
                                            target: {
                                                name: "year",
                                                value,
                                            },
                                        })
                                    }
                                    disabled={isDisabled}
                                />
                            </div>
                        </div>
                        <div className="university-information-form form-inputs course-form-container">
                            <div className="form-group">
                                <Text mb="sm">Semester Offered</Text>
                                <Select
                                    data={Array.from(SEMESTERS.entries()).map(
                                        ([number, { name }]) => ({
                                            value: String(number),
                                            label: String(name),
                                            id: number,
                                        }),
                                    )}
                                    value={String(formData.semester)}
                                    onChange={(value) =>
                                        handleInputChange({
                                            target: {
                                                name: "semester",
                                                value,
                                            },
                                        })
                                    }
                                />
                            </div>
                            <div className="public-checkbox">
                                <Switch
                                    checked={formData.is_public}
                                    onChange={handleSwitchChange}
                                    name="is_public"
                                    label="Public Course"
                                    size="lg"
                                    disabled={isDisabled}
                                />
                            </div>
                        </div>
                        <div className="course-form-button">
                            <Button type="submit" disabled={isDisabled}>
                                {loading ? (
                                    <Loader size="sm" />
                                ) : (
                                    "Save Changes"
                                )}
                            </Button>
                        </div>
                    </form>
                </>
            )}
        </div>
    );
};

export default CourseSettings;
