import React from "react";
import "../styles/App.css";
import { FEEDBACK_URL } from "../constants";

export default function FeedbackLink() {
    return (
        <div className="issue">
            <p>
                see bugs? have feedback?{" "}
                <a href={FEEDBACK_URL}> let us know! </a>{" "}
            </p>
        </div>
    );
}
