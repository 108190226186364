import axiosInstance from "./axiosConfig";

/**
 * Service for handling user operations.
 */
const userService = {
    /**
     * Updates the user data.
     * @async
     * @param {Object} userData - The user data to be updated.
     * @return {Promise} The response data from the server.
     * @throws {Error} If an error occurs during the update process.
     *
     */
    updateUser: async (userData) => {
        try {
            return await axiosInstance.patch("api/user/update", userData);
        } catch (error) {
            throw error;
        }
    },

    /**
     *
     * Gets the user information.
     * @async
     * @return {Promise} The response data from the server.
     *
     */
    getUserInfo: async () => {
        try {
            return await axiosInstance.get("api/userInfo");
        } catch (error) {
            throw error;
        }
    },
};

export default userService;
