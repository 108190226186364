import React, { createContext, useContext, useState } from "react";

const SelectedTaskContext = createContext();

const useSelectedTask = () => {
    return useContext(SelectedTaskContext);
};

const SelectedTaskProvider = ({ children }) => {
    const [selectedTask, setSelectedTask] = useState(null);

    return (
        <SelectedTaskContext.Provider value={{ selectedTask, setSelectedTask }}>
            {children}
        </SelectedTaskContext.Provider>
    );
};

export { SelectedTaskProvider, useSelectedTask, SelectedTaskContext };
