import React, { useState, useEffect, useContext } from "react";
import "../styles/Settings.css";
import PersonalInfo from "../components/settingsPage/personalInfo";
import Preferences from "../components/settingsPage/preferences";
import SeeList from "../components/seeListButton";
import Themes from "../components/settingsPage/themes";
import PrefCheck from "../components/general/prefcheck";
import userService from "../api/userService";
import { UserContext } from "../contexts/userContext";
import { useOutletContext } from "react-router-dom";
// import DeleteAccount from "../components/deleteAccount";
// import ChangePassword from "../components/changePassword";

const Settings = () => {
    const [email_opt_in, setEmailOptIn] = useState(false);
    const [userInfo] = useOutletContext()[1];
    const changeUser = useContext(UserContext);

    useEffect(() => {
        setEmailOptIn(userInfo.email_opt_in);
    }, []);

    return (
        <div className="settings">
            <SeeList />
            <PersonalInfo />
            <Preferences show_explanation={true} type="user" />
            <Themes />
            <PrefCheck
                name={"Email Opt-In"}
                pref={email_opt_in}
                description={
                    "Week In Bytes email opt in (weekly summary of your tasks)"
                }
                onChange={async () => {
                    const request = await userService.updateUser({
                        email_opt_in: !email_opt_in,
                    });

                    if (request.status !== 200) {
                        console.error(request);
                        return;
                    } else {
                        setEmailOptIn(!email_opt_in);
                        changeUser();
                    }
                }}
            />
            {/* <DeleteAccount />
            <ChangePassword /> */}
        </div>
    );
};

export default Settings;
