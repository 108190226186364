import React from "react";
import "../../styles/CoursePreviewModal.css";
import { Box, TextInput } from "@mantine/core";
import { formatAsPercentage } from "../../utils/generalUtils";

const TaskTable = ({
    tasks,
    tableType,
    showGrades = true,
    onGradeChange = null,
}) => (
    <Box>
        <table className="course-preview-table">
            <thead>
                <tr>
                    <th style={{ width: "50%" }}>
                        {tableType === "exam" ? "Exams" : "Assignments"}
                    </th>
                    <th style={{ width: "20%", textAlign: "center" }}>
                        Due Date
                    </th>
                    <th style={{ width: "14%", textAlign: "center" }}>Worth</th>
                    {showGrades && (
                        <th
                            style={{
                                width: "16%",
                                textAlign: "center",
                            }}
                        >
                            Grade
                        </th>
                    )}
                </tr>
            </thead>
            <tbody>
                {tasks
                    .filter((task) => task.categories.includes(tableType))
                    .map((task) => (
                        <tr key={task.id}>
                            <td>{task.title}</td>
                            <td style={{ textAlign: "center" }}>
                                {task.due_date ? task.due_date : "Not Set Yet"}
                            </td>
                            <td style={{ textAlign: "center" }}>
                                {task.worth}%
                            </td>
                            {showGrades && (
                                <td style={{ textAlign: "center" }}>
                                    <TextInput
                                        placeholder="Ungraded"
                                        value={
                                            task.inputGrade ??
                                            formatAsPercentage(task.grade)
                                        }
                                        onChange={(e) =>
                                            onGradeChange(
                                                task.id,
                                                e.target.value,
                                            )
                                        }
                                    />
                                </td>
                            )}
                        </tr>
                    ))}
            </tbody>
        </table>
    </Box>
);

export default TaskTable;
