import React, { useEffect, useState, useContext } from "react";
import "../../styles/Settings.css";
import { UserContext } from "../../contexts/userContext.js";
import { API_URL } from "../../constants";
import { useOutletContext } from "react-router-dom";
import userService from "../../api/userService";

const PersonalInfo = () => {
    const [userInfo] = useOutletContext()[1];
    // get user information
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [university, setUniversity] = useState({});
    const [program, setProgram] = useState("");
    // check if user wants to change anything
    const [changeAnything, setChangeAnything] = useState(false);

    const changeUser = useContext(UserContext);

    useEffect(() => {
        // get user information from database
        const getUserInfo = async () => {
            setName(userInfo.name);
            setEmail(userInfo.email);
            setProgram(userInfo.program);

            // set university name
            const universityResponse = await fetch(API_URL + "api/university", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization:
                        "Bearer " +
                        JSON.parse(localStorage.getItem("authTokens")).access,
                },
            });
            setUniversity(await universityResponse.json());
        };

        getUserInfo();
    }, [userInfo]);

    return (
        <div className="personal-info">
            <form className="form-box">
                <div className="personal-info-field">
                    <label htmlFor="name">Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value);
                            setChangeAnything(true);
                        }}
                    />
                </div>
                <div className="personal-info-field">
                    <label htmlFor="email">Email</label>
                    <input
                        type="text"
                        id="email"
                        name="email"
                        value={email}
                        readOnly={true}
                    />
                </div>
                <div className="personal-info-field">
                    <label htmlFor="university">University</label>
                    <input
                        id="university"
                        name="university"
                        value={university.name}
                        readOnly={true}
                    />
                </div>
                <div className="personal-info-field">
                    <label htmlFor="program">Program</label>
                    <input
                        type="text"
                        id="program"
                        name="program"
                        value={program}
                        onChange={(e) => {
                            setProgram(e.target.value);
                            setChangeAnything(true);
                        }}
                    />
                </div>
                <span
                    className="personal-info-button"
                    style={{
                        opacity: changeAnything ? "1" : "0",
                        transition: "all 0.5s ease-in-out",
                        pointerEvents: changeAnything ? "all" : "none",
                    }}
                ></span>
            </form>
            <div className="submit-button">
                <button
                    type="submit"
                    onClick={async (e) => {
                        if (changeAnything) {
                            e.preventDefault();
                            // update user information
                            const updateUserInfo = async () => {
                                if (name === "") {
                                    alert("Please enter your name");
                                    return;
                                }
                                const response = await userService.updateUser({
                                    name: name,
                                    email: email,
                                    university: university.id,
                                    program: program,
                                });

                                if (response.status !== 200) {
                                    console.error(
                                        response.error,
                                        response.message,
                                    );
                                    alert(
                                        "Error updating user information, please file a bug for this!",
                                    );
                                    return;
                                }
                                changeUser();
                            };
                            updateUserInfo();
                            setChangeAnything(false);
                        }
                    }}
                >
                    Save
                </button>
            </div>
        </div>
    );
};

export default PersonalInfo;
